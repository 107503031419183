import { Color } from '@shared/utils/typescript';
import { InternalCourseDto } from '../../dtos/internal-api';
import { CourseType } from '../../types/course.type';
import { ContentfulRichTextContentMock } from '../contentful/contentful-rich-text-content.mock';

export class InternalCourseDtoMock {
    private defaultValue: InternalCourseDto = {
        id: 'course-1',
        title: 'Course Title',
        subTitle: 'Course Sub Title',
        rank: 1,
        stepIds: [],
        steps: [],
        splits: [1, 4, 9],
        introduction: 'Course Introduction',
        explanation: new ContentfulRichTextContentMock().value,
        color: Color.Lola,
        imageUrl: 'Course Image Url',
        courseCardSquiggle: 'Course Card Squiggle',
        type: CourseType.OPTIONAL,
    };

    constructor(overrides?: Partial<InternalCourseDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalCourseDto {
        return this.defaultValue;
    }
}
