import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, effect, ElementRef, input, OnInit, output, viewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonButton, IonInput, IonItem } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';
import { FeedbackOption, UserFeedbackContent } from './feedback.model';

@Component({
    standalone: true,
    imports: [NgClass, NgTemplateOutlet, ReactiveFormsModule, IonButton, IonInput, IonItem],
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
    // -- AI Chat
    public aiChat = input(false);

    // -- Articles
    public contentfulId = input('');
    public existingRating = input<UserFeedbackContent>();

    // -- Generic
    public feedbackOptions = input<FeedbackOption[]>([
        {
            key: 'not-clear',
            value: 'Not clear',
        },
        {
            key: 'too-generic',
            value: 'Too generic',
        },
        {
            key: 'incorrect',
            value: 'Incorrect',
        },
    ]);

    public optionSelected = output<FeedbackOption>();
    public feedbackSubmitted = output<UserFeedbackContent>();

    public commentInput = viewChild('commentInput', { read: ElementRef });

    public commentFormControl = new FormControl();
    public selectedFeedbackOption: FeedbackOption | undefined;
    public shouldShowCommentInput = false;
    public thumbsDownRating: boolean | undefined = undefined;

    protected readonly Color = Color;
    protected readonly otherFeedbackOption: FeedbackOption = {
        key: 'other',
        value: 'Something else',
    };

    private thumbsDownFormControl = new FormControl();
    private thumbsDownReasonFormControl = new FormControl();

    constructor() {
        // Scroll to the comment input when it is shown
        effect(() => {
            if (this.commentInput()) {
                setTimeout(() => {
                    this.commentInput()!.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 0);
            }
        });
    }

    public ngOnInit(): void {
        // -- AI Chat - we assume that in order to show the feedback component, the thumbs down option (on the page) was selected
        if (this.aiChat()) {
            this.thumbsDownFormControl.patchValue(false, { emitEvent: true });
            this.commentFormControl.reset(null, { emitEvent: false });
            this.thumbsDownRating = false;

            return;
        }

        // -- Articles
        if (!this.existingRating() || this.existingRating()!.thumbsDown === undefined) {
            return;
        }

        // If there is an existing rating, patch the form controls for display to the user
        this.thumbsDownRating = this.existingRating()!.thumbsDown;
        this.thumbsDownFormControl.patchValue(this.existingRating()!.thumbsDown, { emitEvent: false });

        if (!this.existingRating()!.thumbsDown || !this.existingRating()!.comment) {
            return;
        }

        // When the user has left a thumbs down with a comment
        const selectedFeedbackOption = this.feedbackOptions()!.find(
            (option) => option.value === this.existingRating()!.comment,
        );

        if (selectedFeedbackOption) {
            // When a predefined option was selected
            this.selectedFeedbackOption = selectedFeedbackOption;
            this.thumbsDownReasonFormControl.patchValue(selectedFeedbackOption, { emitEvent: false });

            return;
        }

        // When the user selected the "other" option
        this.shouldShowCommentInput = true;
        this.optionSelected.emit(this.otherFeedbackOption);
        this.commentFormControl.patchValue(this.existingRating()!.comment, { emitEvent: false });
    }

    public onClickFeedbackOption(option: FeedbackOption): void {
        this.thumbsDownReasonFormControl.patchValue(option, { emitEvent: true });
        this.commentFormControl.reset(null, { emitEvent: false });
        this.selectedFeedbackOption = option;
        this.shouldShowCommentInput = false;

        this.optionSelected.emit(option);
    }

    public onSelectOtherFeedbackOption(): void {
        this.selectedFeedbackOption = undefined;
        this.shouldShowCommentInput = true;

        this.optionSelected.emit(this.otherFeedbackOption);
    }

    public onClickDone(): void {
        let request: UserFeedbackContent = {
            thumbsDown: this.thumbsDownFormControl?.value,
            contentfulId: this.contentfulId(),
            comment: this.selectedFeedbackOption?.value ?? this.commentFormControl?.value,
        };

        if (this.existingRating()) {
            request = {
                ...this.existingRating(),
                ...request,
            };
        }

        if (request.thumbsDown === null) {
            request.comment = undefined;
        }

        this.feedbackSubmitted.emit(request);
    }

    public onClickThumbRating(thumbsDown: boolean): void {
        this.shouldShowCommentInput = false;

        if (thumbsDown === this.thumbsDownRating) {
            this.thumbsDownRating = undefined;
            this.thumbsDownFormControl.patchValue(null, { emitEvent: false });
        } else {
            this.thumbsDownFormControl.patchValue(thumbsDown, { emitEvent: true });
            this.commentFormControl.reset(null, { emitEvent: false });
            this.thumbsDownRating = thumbsDown;
        }
    }
}
