import { StepProgressType } from '@shared/user-domain';
import { IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString, Max, Min } from 'class-validator';
import { IsStepProgressType } from '../../../../decorators/is-step-progress-type.decorator';

export class InternalPostStepProgressCommand {
    @IsString()
    @IsOptional()
    dogId?: string;

    @IsString()
    @IsNotEmpty()
    stepId!: string;

    @IsNumber()
    @IsPositive()
    contentId!: number;

    @IsStepProgressType()
    progressType!: StepProgressType;

    @IsNumber()
    @Min(0)
    @Max(5)
    @IsOptional()
    rating?: number;
}
