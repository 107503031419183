import { Color } from '@shared/utils/typescript';
import { SeasonalTopic } from '../models/seasonal-topic.model';

export class SeasonalTopicMock {
    private defaultValue: SeasonalTopic = {
        id: '',
        title: '',
        image: '',
        backgroundColour: Color.Alvin,
        topic: '',
        startDate: '',
        endDate: '',
    };

    constructor(overrides?: Partial<SeasonalTopic>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): SeasonalTopic {
        return this.defaultValue;
    }
}
