import { Color } from '@shared/utils/typescript';
import { InternalSeasonalTopicDto } from '../../dtos/internal-api';

export class InternalSeasonalTopicDtoMock {
    private defaultValue: InternalSeasonalTopicDto = {
        id: '',
        title: '',
        image: '',
        backgroundColour: Color.Max,
        topic: '',
        startDate: '',
        endDate: '',
    };

    constructor(overrides?: Partial<InternalSeasonalTopicDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalSeasonalTopicDto {
        return this.defaultValue;
    }
}
