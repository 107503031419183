import { ContentfulLocale } from './contentful.constants';

export const getContentfulLocaleFromCountryCode = (alpha2CountryCode: string): ContentfulLocale => {
    switch (alpha2CountryCode) {
        case 'GB':
            return ContentfulLocale.EN_GB;
        case 'US':
            return ContentfulLocale.EN_US;
        default:
            console.error(`Unknown country code: ${alpha2CountryCode} - defaulting to en-GB`);

            return ContentfulLocale.EN_GB;
    }
};
