import { AsyncPipe, NgStyle } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, inject, input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-svg-color',
    templateUrl: './svg-color.component.html',
    styleUrl: './svg-color.component.scss',
    standalone: true,
    imports: [AsyncPipe, NgStyle],
})
export class SvgColorComponent implements OnInit {
    @ViewChild('svgElement') svgObject: ElementRef | undefined;

    public readonly httpClient = inject(HttpClient);
    public readonly domSanitizer = inject(DomSanitizer);

    public imagePath = input.required<string>();
    public color = input.required<string>();

    svgContent: SafeHtml = '';

    ngOnInit() {
        this.loadSvg();
    }

    //todo: LB look into testing
    loadSvg() {
        this.httpClient.get(this.imagePath(), { responseType: 'text' }).subscribe((data) => {
            const data2 = data.replace(/stroke="[^"]+"/, `stroke="${this.color()}"`);
            this.svgContent = this.domSanitizer.bypassSecurityTrustHtml(data2);
        });
    }
}
