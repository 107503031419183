import { RescheduledStepType } from '@shared/user-domain';
import { IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString } from 'class-validator';

export class DomainPatchRescheduledStepCommand {
    @IsString()
    @IsNotEmpty()
    @IsOptional()
    userId?: string;

    @IsString()
    @IsOptional()
    dogId?: string;

    @IsString()
    @IsNotEmpty()
    @IsOptional()
    stepId?: string;

    @IsNumber()
    @IsPositive()
    @IsOptional()
    contentId?: number;

    @IsEnum(RescheduledStepType)
    @IsOptional()
    type?: RescheduledStepType;

    @IsBoolean()
    @IsOptional()
    isActive?: boolean;

    @IsNumber()
    @IsOptional()
    timestamp?: number;
}
