import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator';

export class DomainPatchDogCommand {
    @IsString()
    @IsOptional()
    name?: string;

    @IsNumber()
    @IsOptional()
    dateOfArrival?: number;

    @IsNumber()
    @IsOptional()
    dateOfBirth?: number;

    @IsNumber()
    @IsOptional()
    dateOfOnboarding?: number;

    @IsBoolean()
    @IsOptional()
    hasArrived?: boolean;

    @IsString()
    @IsOptional()
    gender?: string;

    @IsString()
    @IsOptional()
    breedId?: string;

    @IsBoolean()
    @IsOptional()
    isRescue?: boolean;

    @IsBoolean()
    @IsOptional()
    isApproximateDateOfBirth?: boolean;
}
