import { Component, computed, input, model, output } from '@angular/core';

@Component({
    selector: 'app-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
    standalone: true,
})
export class StarRatingComponent {
    public maxNumberOfStars = input.required<number>();

    public starValueSelected = model<number>(0);

    public ratingChanged = output<number>();

    public stars = computed(() => {
        return [...Array(this.maxNumberOfStars()).keys()];
    });

    public ratingChecked(updatedRating: number): void {
        this.starValueSelected.set(updatedRating);

        this.ratingChanged.emit(updatedRating);
    }
}
