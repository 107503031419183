import { FoundationCourseId, OptionalCourseId, PRE_PUP_COURSE_ID } from '@shared/constants';
import { CourseType } from '@shared/content-api-interface';
import { Color } from '@shared/utils/typescript';
import { RichTextContent } from 'contentful';
import { Course } from '../models/course.model';

export const createMockCourse = (id: string, rank: number, type: CourseType): Course => ({
    title: `courseTitle${rank}`,
    id,
    steps: [], // Uses stepIds
    stepIds: ['1', '2', '3'],
    rank,
    type,
    splits: [],
    introduction: 'test',
    explanation: 'explained' as unknown as RichTextContent,
    color: Color.Bailey,
    imageUrl: '',
    courseCardSquiggle: '',
});

export class CourseMock {
    private defaultValue: Course = {
        title: 'title',
        id: '1',
        type: CourseType.OPTIONAL,
        stepIds: [],
        steps: [],
        rank: 1,
        splits: [],
        introduction: '',
        explanation: {
            data: {},
            marks: [],
            nodeType: 'document',
        },
        color: Color.Max,
        imageUrl: '',
        courseCardSquiggle: '',
    };

    constructor(overrides?: Partial<Course>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): Course {
        return this.defaultValue;
    }

    public setPupStarter1(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: FoundationCourseId.PUPSTARTER_1,
            title: 'Pup Starter 1',
            type: CourseType.FOUNDATIONAL,
        };

        return this;
    }

    public setPupStarter2(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: FoundationCourseId.PUPSTARTER_2,
            title: 'Pup Starter 2',
            type: CourseType.FOUNDATIONAL,
        };

        return this;
    }

    public setPupStarter3(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: FoundationCourseId.PUPSTARTER_3,
            title: 'Pup Starter 3',
            type: CourseType.FOUNDATIONAL,
        };

        return this;
    }

    public setPupStarter4(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: FoundationCourseId.PUPSTARTER_4,
            title: 'Pup Starter 4',
            type: CourseType.FOUNDATIONAL,
        };

        return this;
    }

    public setPupStarter5(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: FoundationCourseId.PUPSTARTER_5,
            title: 'Pup Starter 5',
            type: CourseType.FOUNDATIONAL,
        };

        return this;
    }

    public setFoundation(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: FoundationCourseId.FOUNDATION,
            title: 'Foundation',
            type: CourseType.FOUNDATIONAL,
        };

        return this;
    }

    public setRescueFoundations(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: FoundationCourseId.RESCUE_FOUNDATIONS,
            title: 'Rescue Foundations',
            type: CourseType.FOUNDATIONAL,
        };

        return this;
    }

    public setPrePup(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: PRE_PUP_COURSE_ID,
            title: 'Pre-Pup',
            type: CourseType.PRE_PUP,
        };

        return this;
    }

    public setPupMaster(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: OptionalCourseId.PUP_MASTER,
            title: 'Pup Master',
            type: CourseType.OPTIONAL,
        };

        return this;
    }

    public setTerribleTeens(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: OptionalCourseId.TERRIBLE_TEENS,
            title: 'Terrible Teens',
            type: CourseType.OPTIONAL,
        };

        return this;
    }

    public setTrickStarter(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: OptionalCourseId.TRICK_STARTER,
            title: 'Trick Starter',
            type: CourseType.OPTIONAL,
        };

        return this;
    }

    public setSeparationFoundations(): CourseMock {
        this.defaultValue = {
            ...this.defaultValue,
            id: OptionalCourseId.SEPARATION_FOUNDATIONS,
            title: 'Separation Foundations',
            type: CourseType.OPTIONAL,
        };

        return this;
    }
}

export const mockCourses: Course[] = [
    new CourseMock({
        id: PRE_PUP_COURSE_ID,
        rank: 1,
        type: CourseType.PRE_PUP,
    }).value,
    new CourseMock({
        id: '200',
        rank: 2,
        type: CourseType.OPTIONAL,
    }).value,
    new CourseMock({
        id: FoundationCourseId.PUPSTARTER_1,
        rank: 3,
        type: CourseType.FOUNDATIONAL,
    }).value,
];
