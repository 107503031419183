import { ChatChannelType } from '@shared/constants';
import { createAction, props } from '@ngrx/store';

export const getChatToken = createAction('[Chat Api] Get Chat Token', props<{ chatType: ChatChannelType }>());
export const getChatTokenSuccess = createAction(
    '[Chat Api] Get Chat Token Success',
    props<{ token: string; chatType: ChatChannelType }>(),
);
export const getChatTokenFailure = createAction('[Chat Api] Get Chat Token Failure', props<{ error: Error }>());

export const getLatestMessageDate = createAction(
    '[Chat Api] Get Latest Message Date',
    props<{ chatType: ChatChannelType }>(),
);
export const getLatestMessageDateSuccess = createAction(
    '[Chat Api] Get Latest Message Date Success',
    props<{ date: Date; chatType: ChatChannelType }>(),
);
export const getLatestMessageDateFailure = createAction(
    '[Chat Api] Get Latest Message Date Failure',
    props<{ error: Error }>(),
);
