export enum FoundationCourseId {
    PUPSTARTER_1 = '26DPC7PpyChLdTxgpZBAVq',
    PUPSTARTER_2 = '4ijz0rZYAdiSFZdz8TIXjk',
    PUPSTARTER_3 = '7abJq1xM7hYUNIq1wXABEd',
    PUPSTARTER_4 = '41IHbTXmUEPLNAIGfOvQCW',
    PUPSTARTER_5 = '1r61KZO4sjzMQSuVlTFzpe',
    FOUNDATION = '39poAHkWTrIxtor50AXir0',
    RESCUE_FOUNDATIONS = '41l6tEsYLzW76bhvQWK1U3',
}

export const PRE_PUP_COURSE_ID = 'p07LuCXDqLMJm2iuPPUXS';

export enum OptionalCourseId {
    PUP_MASTER = '6YMjvyGlnCtuUQ6DyRlp0l',
    TERRIBLE_TEENS = '717PIGlp0m28pkKgPspbwI',
    TRICK_STARTER = '2SUk1gooRedXlrCvDatap7',
    SEPARATION_FOUNDATIONS = '2ykAWxArFn5mObdQfZrzfL',
}

export enum ContentfulTagType {
    ARTICLE = 'article',
    LESSON = 'lesson',
    DAILY_BITE = 'dailyBite',
    TOPIC = 'topic',
    FAQ = 'faq',
    COURSE = 'course',
    BREED_GROUP = 'breedGroup',
    BREED = 'breed',
    STEP = 'step',
    ALL_STEPS = 'steps',
    DEVELOPMENTAL_WINDOW = 'developmentalWindow',
    CHAT_TILE_CONTENT = 'chatTileContent',
    CHAT_TILE_TOPIC = 'chatTileTopic',
    SEASONAL_TOPIC = 'seasonalTopicCard',
}

export enum ContentfulLocale {
    EN_GB = 'en-GB',
    EN_US = 'en-US',
}

export const CONTENTFUL_SHOW_FOR_STRING = new Map<
    Omit<ContentfulDynamicContentOptions, 'age'> | ContentfulStringAges,
    string
>([
    ['0-7', 'For 0-7 week old pups'],
    ['8-9', 'For 8-9 week old pups'],
    ['10-12', 'For 10-12 week old pups'],
    ['13-15', 'For 13-15 week old pups'],
    ['16-23', 'For 16-23 week old pups'],
    ['24+', 'For 24+ week old pups'],
    ['rescue', 'For rescue pups'],
    ['non-rescue', 'For non-rescue pups'],
] as const);

export type ContentfulDynamicContentOptions = 'age' | 'rescue' | 'non-rescue';

export type ContentfulStringAges = '0-7' | '8-9' | '10-12' | '13-15' | '16-23' | '24+';

export interface MinMaxAges {
    min: number;
    max: number;
}

export const CONTENTFUL_AGE_RANGE_MAP = new Map<ContentfulStringAges, MinMaxAges>([
    ['0-7', { min: 0, max: 7 }],
    ['8-9', { min: 8, max: 9 }],
    ['10-12', { min: 10, max: 12 }],
    ['13-15', { min: 13, max: 15 }],
    ['16-23', { min: 16, max: 23 }],
    ['24+', { min: 24, max: Number.MAX_SAFE_INTEGER }],
] as const);

/**
 * The default locale used by contentful.
 * This should match the default locale in contentful itself.
 */
export const DEFAULT_CONTENTFUL_LOCALE = ContentfulLocale.EN_GB;

/**
 * The locales that are used to sync content from contentful to the cache.
 */
export const CONTENTFUL_CACHE_SYNC_LOCALES = [ContentfulLocale.EN_GB, ContentfulLocale.EN_US];

/**
 * This is a temporary solution to map the contentful contentId to the stepId, whilst we migrate over from the legacy progress table.
 * Nathan & Guido mapped from Contentful
 */
export const CONTENT_ID_TO_STEP_ID_MAP = new Map([
    ['10102016', '1DDs213qeP0bIE4h5fsGr4'],
    ['1012006', '5J725e4FMtfFelzoD3FZR4'],
    ['10001', '5JZ2N0COY3Ti2avKjxuVqC'],
    ['1010312', '6CsqxsGWLnCCYgU9eOQ7ve'],
    ['40040', 'nsdHv3G5CfqtbdjPwcK0x'],
    ['40021', '1SS8cgl1RPKhNiFI5tiflF'],
    ['13031', '1RfXEdJ7wQRgodcJ9Ua3Q5'],
    ['1012014', '1ytWevqIy7USGfmervIA2W'],
    ['13006', 'Dw3hNDgNRHZFHvv3XqrJp'],
    ['50044', '7aZW36jqF79iYOOoiS8dBH'],
    ['12019', '1idXLeUvje5fnqrzVnsNCS'],
    ['60000', '2Cg0KVyLUzVmSVmiGuskKC'],
    ['60001', '7mY6HihDCE3Jv1KNBewfqf'],
    ['60011', '4tWx83E4XA1b3LK3CWRfxr'],
    ['60010', '4tWx83E4XA1b3LK3CWRfxr'],
    ['60009', '4tWx83E4XA1b3LK3CWRfxr'],
    ['60008', '4tWx83E4XA1b3LK3CWRfxr'],
    ['60007', '4tWx83E4XA1b3LK3CWRfxr'],
    ['60006', '4tWx83E4XA1b3LK3CWRfxr'],
    ['60005', '4tWx83E4XA1b3LK3CWRfxr'],
    ['60004', '4tWx83E4XA1b3LK3CWRfxr'],
    ['60002', '4tWx83E4XA1b3LK3CWRfxr'],
    ['60003', '4tWx83E4XA1b3LK3CWRfxr'],
    ['60012', '2A95cmuziNXkMaoIllqBSg'],
    ['60013', '2YNiXJZGfbo7W8kQ95Xre'],
    ['60014', '1hHQ0xIAtKlDHkiXRAq4iU'],
    ['60015', '2jGG27YWGpO3QNKSj7NqAq'],
    ['60016', '4qPfqLz4hNCkBsYvJwFS51'],
    ['60017', '7towuNuU8daXKMhc31VTbX'],
    ['60018', '47XpsBbVkYRbRlyT1Yy8av'],
    ['60019', '47XpsBbVkYRbRlyT1Yy8av'],
    ['60020', '6rSboKZHrpuatuXmhtxEmS'],
    ['60021', '5RC4sUh8qvYaEVgmX7mbGh'],
    ['60022', '6Ex084O7tAr4ZIKtRzZM3Y'],
    ['60023', '6Ex084O7tAr4ZIKtRzZM3Y'],
    ['60024', '1mgaNXv6iN25OWpFSttjg2'],
    ['60025', '3gTgwp7Q91GpYfHXsXXlfJ'],
    ['60026', '2iB6zsOaaxdArHyaCIaKKF'],
    ['60027', '37CFNoC4tm0TSDxGS9eQtZ'],
    ['60028', '3jEpcUCPT4umAdOBCblYnK'],
    ['60029', 'nOzJ5HgQME03Ykh7qkgND'],
    ['60030', '6XsX7enyyuV24iZs9Yt0GQ'],
    ['60031', '1Kfcxctuo3zUkNZfQfWy6l'],
    ['60032', '1Kfcxctuo3zUkNZfQfWy6l'],
    ['60033', '3fv4DgnLUuTLySzwVbmQMU'],
    ['60034', '74k2zR6tGa3R5rralXgHRY'],
    ['60035', '3iWB3cqt2o1HMYJ2xrboCF'],
    ['60036', 'kKVsBCgcpt53SIIIsqTCa'],
    ['60037', '6clFv08uY3P1RoiQCRozHX'],
    ['60038', '7zuLtXXa1AmBf0AIBjMPZb'],
    ['60039', '2ngmNxwBu6nF7CaSpk5Hs'],
    ['60040', '2iytKEaqAiESGvI1BlqIRC'],
    ['60041', '6Iz6UPxmSBPTdaxSkO1ZEU'],
    ['60042', '2XVOg1D1yA4RRSSqohxKXS'],
    ['60043', '01EY74HMuNh03hadnp3ubM'],
    ['60044', '5BgyZekfRx7zu8ApgDdh3Q'],
    ['60047', '7gX0mmVqirNU1vwdF33iYV'],
    ['60046', '7gX0mmVqirNU1vwdF33iYV'],
    ['60045', '7gX0mmVqirNU1vwdF33iYV'],
    ['60048', '5GqAUZx305LjllPks6ydLg'],
    ['60049', '5OxQ1aipkEofB6uvU1g9V1'],
    ['60050', '332hqYJ9UVLk3PSG89AZIb'],
    ['60051', '3Hj4190aWN4qpMCyQm7B7V'],
    ['60052', 'bqJpYT6BbkHOMuOqF1B32'],
    ['60053', '1xNUYWCJZ3Xqqv822vxnJZ'],
    ['60055', '1QrZGInOXYJ9gI3l983AUU'],
    ['60054', '1QrZGInOXYJ9gI3l983AUU'],
    ['60056', '65SXqGeUCdJqC80VeWQyOz'],
    ['60057', '6x0l9VgxhZp4qdtMLPk2rx'],
    ['60058', '2q77UBOdMZC8UJR3uPeUJh'],
    ['60059', '3slqWbp74yNPiGSUapViR0'],
    ['60060', '6YGc7Z6gUGFq9cZVc8gfkO'],
    ['60063', '1cCy6856Q3XYeuUwuVwWzb'],
    ['60061', '1cCy6856Q3XYeuUwuVwWzb'],
    ['60062', '1cCy6856Q3XYeuUwuVwWzb'],
    ['60064', '37ntNPamI9KRl8YJxopqie'],
    ['60065', '6S017V6pkwElO4nGKQpFuY'],
    ['60066', '31ElBbhXq9NvSj1jvAC9f4'],
    ['60067', '2sdRUpgAr0SEeMStiILjgo'],
    ['60068', 'LsKA2x8yli4LOcUsOKi7z'],
    ['60070', 'pRqmOJCpI1Ea0rgWsokPb'],
    ['60069', 'pRqmOJCpI1Ea0rgWsokPb'],
    ['60071', '7qQfgdunE1vqHdI4fRIRzv'],
    ['60072', '7qQfgdunE1vqHdI4fRIRzv'],
    ['60073', '5yY7tdSCYnNZfCvjrn0bnP'],
    ['60074', '6uY5xuLl21vUFcsEnCKXiB'],
    ['60075', '6FBWR9lOXTEURXlRr6mwPR'],
    ['60076', '7eKIdMKawO20ghcRUEjQKR'],
    ['60077', '3taEVeegqv4Fp4iOvjkPcZ'],
    ['60078', '1v8tBU8DrJjwOXx8nNiGzB'],
    ['60079', '75R1VzuB3edpP3ySdxkqYb'],
    ['60080', '4PZkE3arDbmCPvuVHc4aPt'],
    ['60081', '7rhYQcoxAanfhlE3D61Z2l'],
    ['60082', '1dkYNIjS96sf3OjO9PIRjL'],
    ['60083', '5s0VuXUKipRVOkhZDBlb46'],
    ['60084', '40yWM7AxYgsvhMOvb0csHh'],
    ['60085', '7AMbT3eIaudU6fkBoj1BMZ'],
    ['60086', '1Qr4SF0z3s5rJxUWTeAGoj'],
    ['60087', '4v1uMMNkkPecHZVSe73l0s'],
    ['60089', '5ebiUudSPnskjCV53h80RU'],
    ['60088', '5ebiUudSPnskjCV53h80RU'],
    ['60091', '5Ky5gItxDqr1B1mpFQayB0'],
    ['60090', '5Ky5gItxDqr1B1mpFQayB0'],
    ['60092', '3WqGVbdC1EgkKCQiueTDFA'],
    ['60093', '7ji5Zdi7DYp97MzppzXuvV'],
    ['60094', 'WsHiUvao95n7GXnZRj4i7'],
    ['60095', '5yEy9kVIEaX1eNUkaNBYYw'],
    ['60096', '48qvUQg800vCeYV1aAtuBf'],
    ['60097', '7s0msnj0CHg9Y11R9QUkf7'],
    ['60098', 'EZ1LYCDLcJMQAun35jX5q'],
    ['60107', '60JsUh126GzxcUE3JJFPhn'],
    ['60106', '60JsUh126GzxcUE3JJFPhn'],
    ['60100', '60JsUh126GzxcUE3JJFPhn'],
    ['60099', '60JsUh126GzxcUE3JJFPhn'],
    ['60105', '60JsUh126GzxcUE3JJFPhn'],
    ['60108', '60JsUh126GzxcUE3JJFPhn'],
    ['60104', '60JsUh126GzxcUE3JJFPhn'],
    ['60103', '60JsUh126GzxcUE3JJFPhn'],
    ['60101', '60JsUh126GzxcUE3JJFPhn'],
    ['60102', '60JsUh126GzxcUE3JJFPhn'],
    ['60110', '57xVbdwllTAKpjK63L3lyL'],
    ['60111', '2yF8WcQYbmJVCqxWJZG0ly'],
    ['60112', '2yF8WcQYbmJVCqxWJZG0ly'],
    ['60113', '2yF8WcQYbmJVCqxWJZG0ly'],
    ['60114', '2yF8WcQYbmJVCqxWJZG0ly'],
    ['60115', '7Kz3a2yBLwmHu8bzm2i8QP'],
    ['60116', '744k7XcfjxsvjFVbhTbse1'],
    ['60120', '59boOaCZHDiFYJkFi8dXw0'],
    ['60119', '59boOaCZHDiFYJkFi8dXw0'],
    ['60118', '59boOaCZHDiFYJkFi8dXw0'],
    ['60117', '59boOaCZHDiFYJkFi8dXw0'],
    ['60121', '6lir6J8F3LcxwzD5hZ0zAf'],
    ['60124', '2aXEg5Q2PUZvFnlBcP5Mxk'],
    ['60123', '2aXEg5Q2PUZvFnlBcP5Mxk'],
    ['60122', '2aXEg5Q2PUZvFnlBcP5Mxk'],
    ['60125', 'N0JAazr7sxuglqiHP8Hxe'],
    ['60126', '4s97PYlTwBM4neLaesDlRF'],
    ['60127', '49xarHUiop35jqjWWCqMsv'],
    ['60128', '2Wjkl7ATrb25r2PAAEuuQY'],
    ['60129', '2W7K7gKrhm0VckwgalkNd0'],
    ['60130', '1jrwVuPYMOrwHGhlY1bCGI'],
    ['60131', '4ohhFSiF9Q5cFI8t6OCXUJ'],
    ['60132', '2ComcHXJ3HALacvRgIBkEY'],
    ['60133', 'RbrMG6EpMJ5UE44APEZMi'],
    ['60134', '5kEy7EbEKhK0JTCHRriIo6'],
    ['60135', '1YTcEJr4UwvsPMPryGnCyi'],
    ['60136', '6rIRE2f4dznKabBMIybfvV'],
    ['60137', '1pjBrOZyYjAUBAKGO6fUpx'],
    ['60138', '6cyhjARerkjQp4IGMr39ZT'],
    ['60139', '24z7qRMnJ7y4vvT7QenbJ0'],
    ['60140', '27mIj7ewn7NUfMKDsvTEcE'],
    ['60141', '2rIVMdAgqg8gPN2QLzD4se'],
    ['60142', 'APLusoU1nYIWLXbKATiDU'],
    ['60143', '4z3MlT3x5OCqUuOR9SvFzc'],
    ['60144', '25zEvFRJCqZWzxfTWQ8g4u'],
    ['60145', '7JRb2nVPf7SOrBJE1f4Fyd'],
    ['60146', '2x81LVeprBZM0FuKD6FR2n'],
    ['60147', '4uHLpjUvizNlIjsaAOcQU5'],
    ['60148', 'SBr5pm9KVDL4wWUgu9lIj'],
    ['60149', '5pqHLgaIIZ8kaLj6WzggGb'],
    ['60150', '5EABh2N7NsPgX8flpshZzj'],
    ['60151', '1f3gwtW0rUSCzXR8i0RwZE'],
    ['60152', '4yE0l0coZoh0LAsZ8tKh26'],
    ['60153', '6ysqYfyy4IQ5mn8bQVqqmV'],
    ['60154', '7yAq5pfVu1boJdlCUDI02e'],
    ['1010221', '57ghq8SPO1rNGKkWw4lAp'],
    ['1010220', '57ghq8SPO1rNGKkWw4lAp'],
    ['1010219', '57ghq8SPO1rNGKkWw4lAp'],
    ['1010117', '4meB5ImjqhEchXJvbQGtOR'],
    ['1010310', '6DeJGm9g6xoUnJKMwVYKvD'],
    ['1010309', '6DeJGm9g6xoUnJKMwVYKvD'],
    ['1010311', '6DeJGm9g6xoUnJKMwVYKvD'],
    ['1012001', '3xtRIzPdh19WJIveVqTWG8'],
    ['1010112', '7k6yBK5IZ963qOhOnQOpME'],
    ['1040020', '5BFuar50DVrivchWmV9OJs'],
    ['1010201', 'y9lor4XD282EyxS96jq5p'],
    ['1010328', '1Yw4PMTKDxKWgJoZeCfUL8'],
    ['1010247', '2vRyvQdW6QEShyRSxxvTfU'],
    ['1010402', '1cgGmXdqbxxONbggsm5rPJ'],
    ['1012002', 'ujOAfXNBfcmxFOUuyzGax'],
    ['1010207', '4icYT2OsdbPuqFhXq3iv47'],
    ['1010208', '4icYT2OsdbPuqFhXq3iv47'],
    ['1010206', '4icYT2OsdbPuqFhXq3iv47'],
    ['1040024', '35LUY2eTYkKFm7KYO9hT1r'],
    ['1010306', '3DsWhiwoS9ZPsW0lOUrDUF'],
    ['1010422', '7mc1Ag30LZHKvNbvy1R1pl'],
    ['1010421', '7mc1Ag30LZHKvNbvy1R1pl'],
    ['1010420', '7mc1Ag30LZHKvNbvy1R1pl'],
    ['1010419', '7mc1Ag30LZHKvNbvy1R1pl'],
    ['1040025', '3Am4gRd6sQf6802D1EA1Vx'],
    ['1012012', '6R7lKPXT3oZPF3BT3LUdkd'],
    ['1010228', '7pKAL8tgMpQCo6suuwmevQ'],
    ['1010227', '7pKAL8tgMpQCo6suuwmevQ'],
    ['1010226', '7pKAL8tgMpQCo6suuwmevQ'],
    ['1010611', '7yg5Kk1rtUYabqppLHV2Ws'],
    ['1010327', '20C8yJzzG6ITpEVNxQIHk7'],
    ['1010403', '1rO0MidFRtfJrcCAVqKcxc'],
    ['1010312', '3wQ77Ge41Y8QC1AB34omEN'],
    ['1012014', '5NXjOYct1RgZGp2TxjtlSk'],
    ['1010318', '2UoLLg8gzZ71cfMU5SpLIl'],
    ['1050021', '1MEnXZIRf4i166T7LmxZIH'],
    ['1010401', '6FezUcMM8JSP6rhFkAJnG9'],
    ['1012003', '5nUetA7wJcmXl7FovTlseR'],
    ['10102004', 'AUr51VLobaWzmBKGBW8XD'],
    ['1010409', '3FAtj21L4Bmy68nSMVapGn'],
    ['10103172', '56E9ZdZ2ZX7ZUe7H0mny7p'],
    ['1010540', '5tMnM2tNmLv54gblIMVJB3'],
    ['1010541', '5tMnM2tNmLv54gblIMVJB3'],
    ['1010542', '5tMnM2tNmLv54gblIMVJB3'],
    ['1010543', '5tMnM2tNmLv54gblIMVJB3'],
    ['1010425', '4dfCzgIDoy1tFITpkcIUlk'],
    ['1010116', '1EPKfn5mhYnHABWIjaIRtH'],
    ['1010317', '7uBKosTpzEh3ne28eQALQo'],
    ['1010316', '7uBKosTpzEh3ne28eQALQo'],
    ['1010315', '7uBKosTpzEh3ne28eQALQo'],
    ['1010314', '7uBKosTpzEh3ne28eQALQo'],
    ['105210', 'taOXlZnQ8Kh5IjyUXl9pR'],
    ['1010423', '68kilt6jPfLO3l157BC9iZ'],
    ['105290', '5IEb3sVvdDX1TLZPT0BYSJ'],
    ['105310', '5IEb3sVvdDX1TLZPT0BYSJ'],
    ['105300', '5IEb3sVvdDX1TLZPT0BYSJ'],
    ['105330', '5IEb3sVvdDX1TLZPT0BYSJ'],
    ['105280', '5IEb3sVvdDX1TLZPT0BYSJ'],
    ['105320', '5IEb3sVvdDX1TLZPT0BYSJ'],
    ['1010619', '6Z33WmLAZfb6xpdu99EmBa'],
    ['1010618', '3b1B43Qprjv9gSJueb4xvQ'],
    ['1010617', '3b1B43Qprjv9gSJueb4xvQ'],
    ['1010616', '3b1B43Qprjv9gSJueb4xvQ'],
    ['1010416', '5gVyc6wc9ke0PW7JTFfHn7'],
    ['1010415', '5gVyc6wc9ke0PW7JTFfHn7'],
    ['1012019', '5GMY4ZTH1BK2faC4aCGDWr'],
    ['1012020', '4kzmgl4njE9b3WDTZTlWmp'],
    ['1050022', '7vqCBxEgVcJXsJ5E9wkRc5'],
    ['1010408', '1c2nJWVuMMxEqOIou2Y4xO'],
    ['1010407', '1c2nJWVuMMxEqOIou2Y4xO'],
    ['1010406', '1c2nJWVuMMxEqOIou2Y4xO'],
    ['1010405', '1c2nJWVuMMxEqOIou2Y4xO'],
    ['1010414', '4uHilJZcqZKPIQlsDfGc0'],
    ['1010504', '6xARFvRbR2ptydMsKXTStk'],
    ['1010534', '1n2Z9wFqQrGHncimKiCG7I'],
    ['1012015', '2S9t7tNStIdVsugcJCge5f'],
    ['1010527', '2Ahs7n5Jqn0pNWlT3dDlFa'],
    ['1040018', '5qZJ7tA0BOybNPCFIM1HEH'],
    ['1010609', '7gIJ8kpgLOG60YIPOlqYQO'],
    ['1012021', '1A5fNIUNwxITFYK12P1qib'],
    ['1010512', '1TYa2OB7gv6gWutRVu219R'],
    ['1050023', '2EdejDy6YBLzIKRV5cron3'],
    ['1010535', 'cMGpNzGBiuz5lNrZ6gkUL'],
    ['1010614', '4QTGnxt1ckI8LNAe4pTRHC'],
    ['1010608', 'FQk79yBcsGsin2BU7fWse'],
    ['1010607', 'FQk79yBcsGsin2BU7fWse'],
    ['1010412', '3DoCUXCctrBNGN2TNLCbfJ'],
    ['1010501', '7K8DYR62FQUrDaRm6DSjTa'],
    ['1010502', '78kHr3qgOwY8x5FLr3iCkM'],
    ['1010620', '4k3J22BSWHPtt6HyIiGpPH'],
    ['1050008', '4ndX8qCGzh13k7QouMxCaj'],
    ['1010711', '2wnrIwrsjAOQrRyreKKlC2'],
    ['1010727', '3CvkXCzP6VxbuuIt7zKOX4'],
    ['1010726', '3CvkXCzP6VxbuuIt7zKOX4'],
    ['1010725', '3CvkXCzP6VxbuuIt7zKOX4'],
    ['1010724', '3CvkXCzP6VxbuuIt7zKOX4'],
    ['1010723', '3CvkXCzP6VxbuuIt7zKOX4'],
    ['1010722', '3CvkXCzP6VxbuuIt7zKOX4'],
    ['1010720', '3CvkXCzP6VxbuuIt7zKOX4'],
    ['1010718', '3CvkXCzP6VxbuuIt7zKOX4'],
    ['1010721', '3CvkXCzP6VxbuuIt7zKOX4'],
    ['1010719', '3CvkXCzP6VxbuuIt7zKOX4'],
    ['1050005', '01vfHA4gsSesKQ5sJ48hZN'],
    ['1012022', 'ZA3hwe1sTnTZrWglncSVd'],
    ['1010710', '4XZmhs8r9m1d3fuZCYIFRc'],
    ['1010705', '4tuPvcJkfRLIPLYdW7v3wC'],
    ['1010615', 'NNN8F4bNvPLHyLYCAHDdO'],
    ['1010404', '6WpV25Rv2Cih7I5CtE6ean'],
    ['1050001', '2kHRyPCxIyEywlwbeg5xEJ'],
    ['1010606', '5FtpEn8PbSfjg5bSCnWIpW'],
    ['1010605', '5FtpEn8PbSfjg5bSCnWIpW'],
    ['1010604', '5FtpEn8PbSfjg5bSCnWIpW'],
    ['1010602', '5FtpEn8PbSfjg5bSCnWIpW'],
    ['1010603', '5FtpEn8PbSfjg5bSCnWIpW'],
    ['1010816', '1OJ9XZSnjGa3uflO3vDsON'],
    ['1012017', '7qDWusCY4UZLlkAE5cRwad'],
    ['1050009', '2KnNxVKnwhx8t91rEshOJ7'],
    ['1050010', '1iYWA107VEmEL0B00mevOB'],
    ['1050011', '5LJW2jBQHiB3iLqsKoImIm'],
    ['1010413', '6vj06p00MQjobm8LK0x5x5'],
    ['1010613', '5QztOkccb3CYFJL8ml7HQn'],
    ['1010712', '5XddDbUQqnXwoQnTEm9EsG'],
    ['1010713', '5XddDbUQqnXwoQnTEm9EsG'],
    ['1050006', '5ENMiQnGBC1qKKUjTxOYoi'],
    ['1012023', '2tbPjh2yyVIo7JKYLGgTvC'],
    ['1010715', '6ydG6vmTU1tt9sNJblvuX5'],
    ['1050002', '3cXQvdCAdt6QgiZAr1ssaq'],
    ['1050003', '5WDR9r6OK1OQOhRenl2pOU'],
    ['1050004', '6D1HR5i7jfemskqfrm5Eg6'],
    ['1050007', '20F4KncGjM4hppmRKLc79g'],
    ['1010329', '5cbictaqeUQQZGpl2U8Vy5'],
    ['1010410', '7tNGOfBZT8HlpCGx0RePaA'],
    ['1010538', '4MJjvQocLxa0JEdWtfTnkF'],
    ['1010626', '5ycEpHIkXNNPLdP4IFhNFf'],
    ['1302201', '7bbtiks7FvOiO2Y9HHkDZ5'],
    ['1012005', '3wTnM48m6IVh6hok6sozIa'],
    ['20118', '6m52XFLSp5Yj6lS0uyXRgL'],
    ['1019940', '37w3qrXQfKB9B2EUSucdOn'],
    ['1019930', '3KVS1cU9xqMlRgpSBHfXDj'],
    ['1040160', '7nqaRKpMrK5YP47F3dxMnb'],
    ['700051', '72G8NFWFRaePggtIP5bEla'],
    ['7000510', '1XDrzYF9pt6kslAK3u8uBX'],
    ['700049', '47xVeqrVNJToGKtF7Sbvef'],
    ['700048', '50Yrzi8Bx7v3IeEotbqkUv'],
    ['700047', '79s8Ac9kUVbTzw77hzV3LC'],
    ['700046', 'uVXxYRSDF5OTDcRzMFLd8'],
    ['700050', '4uR6gyxaGMIRTUefPhDpSx'],
    ['700045', '6AdZkf403lBvsLcIIFLSKU'],
    ['700044', '2UCBEQds9LsNrZGA5abqPT'],
    ['700043', '7seGrmsdmNG8KfHN17t8Ld'],
    ['10716', '39fprhCqJqr1RLRHhGBxgW'],
    ['700042', '6rubGk7UZitJEmeBGDVN97'],
    ['700041', '1uHoQP1jnxdV5z9gEE75wH'],
    ['700039', '3kUEPuIo08Xf46AwExB3ih'],
    ['700038', '1gsUvJDeVWSiorjmlAkFFh'],
    ['700037', '6NszVEtmZOrNyVGMpPCvkc'],
    ['700036', '4Ghb55O16qEj0l6QW5qCa2'],
    ['700035', '6UfwL5pTXQzZLyOBoiqMpP'],
    ['50048', '1vruPynmQr5vwTwoNML13Q'],
    ['50047', '1LLD91pv4q2szaBKlSU2KS'],
    ['50045', '5EH1FQjDkKF0hcIa6mAhwH'],
    ['700034', '621V7uNbgZIGErEF4LaGzA'],
    ['700033', '7j4MbX14rvjshp8ukJWWpi'],
    ['700032', 'Znfc3FBvfcHGsux4ryym6'],
    ['700031', '49QIXf94uuiIaJuo4QVNRt'],
    ['700030', '7s2vgydLL8UgcJVIbWdbOY'],
    ['700023', '6cD6tDjSRUtNW3ZtBCqIY0'],
    ['700028', '5o90zp9Yfl5mP6VRd4oyaL'],
    ['700027', '3VSxSSIOR1Rd5YAG9yYpZs'],
    ['700026', '1cvuTlk7oCXM5WC5GuYK9A'],
    ['700014', '5ncu7uSNP7lgh9FexOdRRu'],
    ['700022', '4JB85vSvQmrlwIqjCHut4k'],
    ['700020', 'icDahU3yd8JMyNnF05sbP'],
    ['700040', 'eVTFC1EErcUJmlMq6CUqe'],
    ['700024', '4abmbxPYO3dkPMFCQHgLV4'],
    ['700021', '7ypj8RjyqoiCjvf5gatREH'],
    ['1040150', '3QRk2bLYIvHJM71Qi4qMOE'],
    ['1019950', '6HDsu2jCXpO8cXiU05zarB'],
    ['1019920', 'crk5rHyWot8vthuQb8aJ1'],
    ['105132', '1bWMzbLV0YVhDU0jRsLaqX'],
    ['105131', '1bWMzbLV0YVhDU0jRsLaqX'],
    ['105130', '1bWMzbLV0YVhDU0jRsLaqX'],
    ['700017', 'UoEVNwrtHmLOFMwZSM28W'],
    ['700016', '2LX4Wn7lwYPsm415RUNkNn'],
    ['400150', '1Xy7EpvPhMED2EriuzQfkh'],
    ['1019020', 'ayIsxcvjmzDUf2IQXbz9M'],
    ['1019910', '5nuP1ADS5oSfC0u26JoSyT'],
    ['700013', '1iDTeeaSlCYvxYyNFit2oT'],
    ['700012', '1iDTeeaSlCYvxYyNFit2oT'],
    ['700009', '7KQAiXUA5mMT4nWhTHmQB2'],
    ['1013710', '2V5vUD4GJpXR88RnEbweDH'],
    ['1019010', '2Dou5GvMbyQ2brj8pVahps'],
    ['1019900', '5Dp2YmMVuYD0VzTiuieN89'],
    ['700011', '6B0rYKMWtgyal5BntalVQX'],
    ['700010', '1PRgaKI9OMBKHiGLbZA7k4'],
    ['700008', '1ykMtz4v9Jy9Vr17roQL58'],
    ['1400100', '3YzEBeVBIpug6EbZGtXYLE'],
    ['1023250', '4XFq0w0wp5vRHS006MHwOt'],
    ['700026', '7aLmb6MOyEt78uwcHC0RrI'],
    ['700006', '4PuDJ7vih2KrYvAy0VAEL9'],
    ['1013700', '5li24jaPh6UZhyoHxkkNa'],
    ['1023100', '0Mwzv6zVzYdDu3ZpzFa62'],
    ['700005', '41FTzoyiU4wPVY5qPRCB39'],
    ['1012900', 'fcdgwVvXEmMlbYDvuJjIg'],
    ['1023300', '5NDPcFsxJzSGZ431TunAhw'],
    ['700004', '70Daqf0UXQVgtIunAjFIxa'],
    ['700003', 'kDvWD9DJoQit44oq62xJl'],
    ['700002', '2ZNV9ukfI7L5eQt81pi5eI'],
    ['700001', 'EXJpI5ggAOxRdqOVo8nat'],
    ['1023250', '2C797G2oGYAehK3ZWqYoNG'],
    ['12032', '1iTC1wNLIfyiVBhPtnXqsx'],
    ['12031', '40lLCaePZwYXG8JQ2GMvCg'],
    ['12030', '7p7sJXVSUwTN3bRRLmbJ3L'],
    ['12029', '1siNrNdwCth1O38kUDvd5w'],
    ['12028', '5yHaSngOYwuptOd45dZBKt'],
    ['12027', '3mEJUuSPY96x5NVHIuhtiT'],
    ['12026', '4VOcyY5462UjCMzEg9XidE'],
    ['12025', '3WLuMJyjWlslseXF1TehEC'],
    ['12024', '3TrjHZ7RamTTA4p6LBtUa'],
    ['12023', '3Xs6w2oidCvT0f1JfOVvzI'],
    ['12022', '87j9sutD3LZQOWhisBamg'],
    ['12021', '3vEzm79NwQAZOmGb2JnFbY'],
    ['12020', '5qmeNHCGAyLeQpAZF3hLEU'],
    ['80002', '10Zw3sJLzu170vxNGWOKhz'],
    ['80004', '3W3m5lwCOwvXXbMERb9AJf'],
    ['80001', '2acR0qeSza0YHE8Z8TmYvu'],
    ['80003', '3g3s6bhiMP5Na0Vp5RerBF'],
    ['103172', '3ly9hIU48wwlqOwaQBcAI8'],
    ['10809', '3D2VcIZhRocK9zpP43jY2X'],
    ['10808', '3D2VcIZhRocK9zpP43jY2X'],
    ['10807', '3D2VcIZhRocK9zpP43jY2X'],
    ['50060', '404ZtdGQwTVHrZNEdJS1Q3'],
    ['50059', '4ZfCE8LfF4O8LuWjFx9sux'],
    ['50058', '22tonHIErGOYPAnxr2v6ax'],
    ['50057', '16cr9ApxBDgTTyVkwvDz00'],
    ['50056', '3U52aiyL6d2eQ6TXrPvwY6'],
    ['50055', '338uLmJ8fevGdr6miJvdjG'],
    ['50054', '7sGkarGRLtBwHGkjEggOgV'],
    ['50053', 'NL1ddo8aNFZvNKbXsnPQ6'],
    ['50052', '2SydEgOFhHQuN0wbLswt69'],
    ['50051', '2gCs1VQFroIM7GlDTOT8jJ'],
    ['50050', '5st3tstOqBIeBQbOld5MHR'],
    ['50049', '55H2T4rXaVTCaoJlw77y0g'],
    ['11109', '4hJpuPTMx9mAPueHmFd7ne'],
    ['11107', '6GnJUyy6aPpGzsSth6Y49E'],
    ['11105', '30VLynBTsEPdbKS24kr8Ji'],
    ['50048', 'FoshFmsjsys8kWdb6sHam'],
    ['50047', '27yrJCy1J2G3NE7v6sGWXz'],
    ['50046', 'ntXQmKojKRONQEzzSSlxT'],
    ['50045', '27V6ofA2FPB4nIT3Cb7qwS'],
    ['50043', '6hAo91dQmfu1tLu6DMCJ5J'],
    ['50042', '3BF4EzUpP6q7SW3X8F9lsy'],
    ['50041', '6RvyT2I31rGpdEeA2kcKIH'],
    ['50040', 'tlxYiXSUVNuEHwcn6mGDq'],
    ['50039', '3cXVWpSQ4NH5uFyZ3Tz4X7'],
    ['50038', '7gssZn71Rz67kg0C44mAu'],
    ['50037', '5JxN8H5IgfZoXln1SNZ8GO'],
    ['50036', '2u3jCYPFijD94M5w3yMuhS'],
    ['50035', '7uxwdrckbFi4wF9HGefyHe'],
    ['50034', '5q8fsNBtKoROKPRMJTrHN5'],
    ['50033', '7e5USCTIK9iTWG5yn0HoLz'],
    ['50032', '4M6C6wqecbUHmLAxcxRFqf'],
    ['50031', '5TFKdlGEDGBRgygqZTNoxW'],
    ['50030', '79dmoeGH3YEZflSskaz5z6'],
    ['50029', 'glHSeSLZxkp3wNNZWgtlB'],
    ['50028', 'xUGlcd4q9ZLfjDFgG1ERK'],
    ['50026', '5cEdOOK7OkHvj0hpGZRzxh'],
    ['50027', '6wqadO7eboxfsz0V7XTEJ0'],
    ['50025', '6tBUyc1Eo46E4JVB97KOlq'],
    ['50024', '2ga0X8Q515kHQfwaPjRJhH'],
    ['11012', '25Jft6EnrRhHEmlVuiQwXp'],
    ['11009', '4UnfpMlcgpb5pLmgonR82N'],
    ['11004', '715p7mD2fdE7jBWPKQedqc'],
    ['10912', '3iKYJlGNFNVJjAX435L9f8'],
    ['10905', '1t94UdqCEVzei02g5OfUpZ'],
    ['10901', '3x0zYYQHpYuu3xMExCP8za'],
    ['50023', '1GtHkX9IfT1eXbZA4dXBDz'],
    ['50022', '7D7ZQ93W54ns4ngEN95Pw7'],
    ['50021', '1wAW88RUa1iDQT2CzdSP9h'],
    ['50020', '14D0AbP1fY2eSz4Dys3jhV'],
    ['50019', '2cNUNz2Se7hrTQa39zfCZL'],
    ['50018', '3qZgCuc2qEb3oO0kHLkI2f'],
    ['50017', '5CslQQzVYZrVrDAQkgMGPo'],
    ['50016', '7byGiCE9ZljICKqDmRy9wc'],
    ['50015', '2wiQrSlQekrSJEprJ82knU'],
    ['50014', '5uMOSWknOYorDibCMtA0XS'],
    ['50013', '26okb5AFm2US9oXh5EtlCT'],
    ['50012', '5hTJUwrblQVAlHFkjqVYZ5'],
    ['50011', '2kzvNXjJZaYFil9npamCIH'],
    ['50010', '3IOCb1IJWyTop23wKBQMey'],
    ['50009', 'rAGUQEPB9KfyXZYfR5GbY'],
    ['50008', '3Z6NTjc3sUN2e9IERin3iL'],
    ['50007', '4uohqy4gbO2Ke4euEXbfME'],
    ['50006', '4Fjp6vmc2124eYCgLSTC3'],
    ['50005', '1dfSIssV3ZNFwAr3jVGt62'],
    ['50004', '3LdDA9YDel81IXnpQy52WX'],
    ['50003', '6BiiOsfSsBY9tyaI4iNh8Y'],
    ['50002', 'ULKGmzTtsh5TtXLpu6cOT'],
    ['50001', '3zZ5lj2KAktyNWtWluAh1Q'],
    ['14001', '62iPrAK2GKvxveWH1as5Qw'],
    ['10417', '3vxDxkaXy3JFRtdEfjFK8k'],
    ['12003', '3tTbfgSjpy64u0cbx8GPb9'],
    ['12006', '5QwVngRHmSAH8Xbz2Sm06m'],
    ['12011', '5A6RGo9TUIVuoAvDP5jUGN'],
    ['12005', '1131vXezfpnAt7FHqY3Ox2'],
    ['12010', '3B81P0zpFelyRmNkWdXbjo'],
    ['12009', '2RLKVMAviicczqCWLXah92'],
    ['12004', '5MC0XoEtiV26akG3GgQYXE'],
    ['12017', 'mcRLW1xuPqbnEsxqdDJeT'],
    ['10301', '2ic3qLf8vDt7l0Ja0aGZSm'],
    ['10302', '2ic3qLf8vDt7l0Ja0aGZSm'],
    ['10303', '2ic3qLf8vDt7l0Ja0aGZSm'],
    ['10304', '2ic3qLf8vDt7l0Ja0aGZSm'],
    ['13013', '5y0GXs9naMupU7RSvYwuj3'],
    ['12008', '6EqofFbSIXKuk2uRb0rACk'],
    ['102016', '5ivQRJHtIExWSjPKpycIAA'],
    ['12002', '1PT1yPjr9uFolBgC9NVfnA'],
    ['13011', '5pJ28WA4dgypTU9iopymWv'],
    ['13010', '23vvDRVynN2BXcbR1TMyqr'],
    ['13008', '2DyidI52KBQQvpigNtS4ey'],
    ['13009', '3QMFUfxsPUANGVpxXZCmbh'],
    ['13007', '4fk3vdJ5u9ylwVxUagX0Zp'],
    ['13005', '3xpKvBXfaTzacIYNAPN9kL'],
    ['12015', 'wqFHXZe7Xnm10jXybxDTg'],
    ['12007', '1EYnxNmi9jCrJMnE4mS45F'],
    ['30103', '4W8sCknyoEmrinSQS7Qrw5'],
    ['12001', '1zEytItcGaxvAKKjtzcwNm'],
    ['10402', '68mbCNQ0BVS7H4yIUDCSOK'],
    ['12013', '12acZQHSLhwJlF9C2WeMtV'],
    ['12012', '6NfTizZiX4xKYT57Zf7XxG'],
    ['13033', 'K5CEEwHSDs9qSZ6N9gU0v'],
    ['13032', '5BP1LRd35vGl0Yrg72Hej2'],
    ['13028', '1lUnAjv2o33ivd65Z4FPma'],
    ['13030', '1vdGyXurVqfDk0IZQDKSnx'],
    ['13029', '4Mq3BD7UBxPt1Ya0Z7kA5i'],
    ['13027', '1bLBhoDj3HUDtGGPwlBNg7'],
    ['13026', '4u7M1ImwLfzUOyfHaJ2UDG'],
    ['13025', 'uzK9X7F9zMImioni9epSo'],
    ['13024', '264Z3nMgMAjUrPN5hvgFP7'],
    ['13023', '5r37Btv0p7N949aIlAnGVn'],
    ['13022', '47ljI8K90gNHT4QV56RhgI'],
    ['13021', '2R2fYCkJO4L8rqYlFhdQiT'],
    ['13020', '1I4jZWrwq9gjk6jOUwi7g7'],
    ['13019', '3iDvz8x5tQpuWzhSIxlkER'],
    ['13018', '6INbYntghe1Kav5tOj2mct'],
    ['13017', '24WSXXaA1NjubUG9eWv69U'],
    ['13016', '7Jb3km6xmi28Sslg4EXdOC'],
    ['13015', '7oEt41sSGxxTUCEXbYBc0T'],
    ['13014', 'zgTh0z01Dk5kq9Kp4Hhyh'],
    ['13012', '7sMxnBlETuZo8JIxnULAhZ'],
    ['13004', '6OuTXnaYep6s0DFJjAG2kR'],
    ['13003', '1ouye8lIWZuZrAzwBmVorA'],
    ['13002', 'OUxnw8tAI661m2Vkks1ZG'],
    ['13001', '75SbvQuZYxCPFEoEZHcW2Q'],
    ['13000', '2OyXhZAPOhBkEGTxIb9NJA'],
    ['40044', '2HWSrkdT5TODAE9cRhqtcw'],
    ['40043', '6KGWTrSKiePzZTyx43gl9x'],
    ['40042', 'q4J4617UTmyc49w9IjpJT'],
    ['40041', 'mmcgkqY6Cn6a2MlWgboVK'],
    ['40039', '20syqG54xKzwRAW7GQkmx4'],
    ['40038', '3LK8co2ee0v93wm4ogU9BV'],
    ['40036', '7qFdF2JaNrywWjuwf9cGum'],
    ['40035', '2vbLNudMK7nzWmJVb2hMcS'],
    ['40034', '1LRY3rzqHJjddhmYdAKIHt'],
    ['40033', '2EWP9wwKsmxyf16nw5zPeH'],
    ['40032', '61qrkox66lXtQeO6ZV0QpE'],
    ['40031', 'PVLnQgz5pwGBlw6yWh4zJ'],
    ['40030', '1ekCbFPcxhuvK9gLfzTkzF'],
    ['40029', '17vPk5tnnQXDCggpKwUaLh'],
    ['40028', '6dNjbAETGTkjdj13oPZoqM'],
    ['40027', '14mcQoFGoPcuLDq26jrT3L'],
    ['40026', '2f5I2JJ2gTjPMluR4t58Ll'],
    ['40025', '2Vskpy0h0B1cR9ifuycROo'],
    ['40024', '5YqSCaqGQhsabwwvzaX9Wy'],
    ['40022', '6pofw6prO1vrVkeUizMpA4'],
    ['40020', '1SSMylrcU3Et04vFRayoKt'],
    ['40019', '3hbCyUwuDf6dVSG0yRisBG'],
    ['40018', '6fzFDU80wUCIdDQBLmlIWN'],
    ['40017', '7cG3f6muf3p2ikZEQbwDSu'],
    ['40016', '3z0sZgz5QZZOgI0eYEjvti'],
    ['40015', '4ZhdoMnVcCE87fg8rnM3iv'],
    ['40014', 'EnZwp4lzWdqAoPCgSPISl'],
    ['40012', '44fUAPtf9cQCr0jyynYy44'],
    ['40013', '5AzZdDXJctnQO29FBPWCo9'],
    ['40011', '4ToELtcavuVjaUN8CDu21o'],
    ['40009', '11uF7xtrB8x9Vh8kYSbzfj'],
    ['40010', '11uF7xtrB8x9Vh8kYSbzfj'],
    ['40008', '3VIG7cjW3Jot8d6RZw0kwE'],
    ['40007', '6KgCsFh7KKC0X0tDGQEdbq'],
    ['40006', '3mvTyZzXxuanJ0nrwzTr5l'],
    ['40005', '3LnDqFuP89kVuPSdRNQmHh'],
    ['40004', '6fJQK76dtcrNKmUePVf2x0'],
    ['40002', '6MOF9GJnk0XTgEMcn40WDi'],
    ['40003', '6sdOLT6XWD3YXHDLEz5vxC'],
    ['40001', '4yHak0zokQLaW3zlnW58bi'],
    ['40000', '6OcFajDi3Ge7FaoNw7C5Wj'],
    ['11113', '5uCRWCFsIfRLSeRIBKGPlU'],
    ['11110', '5bItLIWCIdFDXiz4n39f3X'],
    ['11111', '5bItLIWCIdFDXiz4n39f3X'],
    ['11112', '5bItLIWCIdFDXiz4n39f3X'],
    ['11106', '3GmnDVmndwCvgM6ZVUVaM0'],
    ['11103', '18SV75X1aWo6jIbcFrXSgo'],
    ['11102', '2OI2E1Oa1jg464OkdRCcLg'],
    ['11013', '6nGcjHafbdFjPOuCk51DiL'],
    ['11011', '2dmU2mGR8FAyg2T2D7jQ1c'],
    ['11010', '6OmbtiuiVrXU6zW835UjTU'],
    ['11008', '7FElfRTsCIpROyKHssXtGV'],
    ['11007', '6E1dw0AXa4DOZnMxwndZZ3'],
    ['11006', '5MRu03uDmEQGZcMrGepMQ9'],
    ['11005', '5uvwzZQ1IDnlQvQF1Lnd5D'],
    ['11001', 's4U5y8STQhNX8D94LvMge'],
    ['11002', 's4U5y8STQhNX8D94LvMge'],
    ['11003', 's4U5y8STQhNX8D94LvMge'],
    ['10913', '6HsPM1rjspoQHhwpM0jJmk'],
    ['10911', '3sbWxjT87uEGb5xNYYRacw'],
    ['10910', '5dA8aXVWaOxHjOFLJ53dmV'],
    ['10909', '3eexsckTylKawElEDG3YIN'],
    ['10906', '71bgZwoCwAGDh18X5RT4q1'],
    ['10907', '71bgZwoCwAGDh18X5RT4q1'],
    ['10908', '71bgZwoCwAGDh18X5RT4q1'],
    ['10904', '5JD3AeN0aLjEW3KGc5RPqF'],
    ['10903', '7JowNFMXNHgd3PhpzR6VEU'],
    ['10902', '2oqeHYcVdplmXtNgRuG3NW'],
    ['10832', '1oGitwpcwm5Z7T6hpKktOX'],
    ['10831', '60fBTQVuQrR3aHNnXRFIMj'],
    ['10830', '6qNFxadEjDmNDnN0sSr7Rv'],
    ['10820', 'NW6M2WucCMoptESdNMPkH'],
    ['10821', 'NW6M2WucCMoptESdNMPkH'],
    ['10822', 'NW6M2WucCMoptESdNMPkH'],
    ['10823', 'NW6M2WucCMoptESdNMPkH'],
    ['10824', 'NW6M2WucCMoptESdNMPkH'],
    ['10825', 'NW6M2WucCMoptESdNMPkH'],
    ['10826', 'NW6M2WucCMoptESdNMPkH'],
    ['10827', 'NW6M2WucCMoptESdNMPkH'],
    ['10828', 'NW6M2WucCMoptESdNMPkH'],
    ['10829', 'NW6M2WucCMoptESdNMPkH'],
    ['10819', 'iEQoHgLSvghmhq8Soqo9U'],
    ['10818', '3SmwbfzsbbjrInaXiWPYdN'],
    ['10817', '6Nz363vC41pABz4PRf58gX'],
    ['10816', '71er2vT4a9ztoBhSjBu7Mf'],
    ['10815', '6WqYasI3jKmSujTs56MFv2'],
    ['10814', '1kII6tuBmPgKy5ER4BLrMs'],
    ['10810', '6fZZJ1Bi7Mspkmq3vBoxjt'],
    ['10811', '6fZZJ1Bi7Mspkmq3vBoxjt'],
    ['10812', '6fZZJ1Bi7Mspkmq3vBoxjt'],
    ['10813', '6fZZJ1Bi7Mspkmq3vBoxjt'],
    ['10713', '5NGVsnelrm10xCKGUqfOH9'],
    ['10712', '5NGVsnelrm10xCKGUqfOH9'],
    ['10806', 'yiaa6jdQZN566Jqd5doBf'],
    ['10805', '3Rr7fSAxqu2XgOhq0J9c0p'],
    ['10804', '24ysCj3VATMjduVPQyIZhe'],
    ['10803', '6a7Gj0vj4W4xSILmfUMe6i'],
    ['10801', '206KIUtTtnqSmnfWn3Syu6'],
    ['10802', '206KIUtTtnqSmnfWn3Syu6'],
    ['10738', '5pwF6JbqiCSJYyJJC7PX7G'],
    ['10737', '3uMLBrqErVtmaQhyEMQAH0'],
    ['10736', '58QUQUfdNDJKVYhOJKI1gF'],
    ['10735', 'y8s1v8BPHEwQQQR3fMGe7'],
    ['10733', '5aia7FCGUxEHUscpm7rYqk'],
    ['10734', '5aia7FCGUxEHUscpm7rYqk'],
    ['10729', 'qJhc0fZlqnvmspKbsUezb'],
    ['10730', 'qJhc0fZlqnvmspKbsUezb'],
    ['10731', 'qJhc0fZlqnvmspKbsUezb'],
    ['10732', 'qJhc0fZlqnvmspKbsUezb'],
    ['10728', '6KbwwKyNGRFKr68qEf8vzE'],
    ['10718', '54byE0Vpt8J5m8c54ahRMj'],
    ['10719', '54byE0Vpt8J5m8c54ahRMj'],
    ['10720', '54byE0Vpt8J5m8c54ahRMj'],
    ['10721', '54byE0Vpt8J5m8c54ahRMj'],
    ['10722', '54byE0Vpt8J5m8c54ahRMj'],
    ['10723', '54byE0Vpt8J5m8c54ahRMj'],
    ['10724', '54byE0Vpt8J5m8c54ahRMj'],
    ['10726', '54byE0Vpt8J5m8c54ahRMj'],
    ['10725', '54byE0Vpt8J5m8c54ahRMj'],
    ['10727', '54byE0Vpt8J5m8c54ahRMj'],
    ['10717', '5H6JLKJJo3fIHmOKlyougD'],
    ['10716', '53dNzdeGNOJRY3Uuixq2D6'],
    ['10715', '5HZ0iraVVcy35Eb6Msh8sy'],
    ['10714', '4BATs0pVtVAYXW9YXlStNJ'],
    ['10608', '3x7edrkv1h8oA48DaIodXu'],
    ['10607', '3x7edrkv1h8oA48DaIodXu'],
    ['10711', 'xOsoeFtNHAdHkDxJPV6ty'],
    ['10710', '7n1nUVnJGzYcMre3T7yPwr'],
    ['10709', '7tk6qHWQ08vrfcBKNnVR6B'],
    ['10706', '6K4gQA629bl195I5BhJO2t'],
    ['10707', '6K4gQA629bl195I5BhJO2t'],
    ['10708', '6K4gQA629bl195I5BhJO2t'],
    ['10705', '3Jj5m7ITDbDJEo6eeeQ4WI'],
    ['10702', '75QmLc7MBXi1pjMO1nWWQ0'],
    ['10703', '75QmLc7MBXi1pjMO1nWWQ0'],
    ['10704', '75QmLc7MBXi1pjMO1nWWQ0'],
    ['10701', 'AyTkQZXMHAfaiHNn4mwRG'],
    ['10632', '5KXgKogcW49oJd7eF2LRPq'],
    ['10627', '2rjkp6UhbZvruw0YRxyOhI'],
    ['10628', '2rjkp6UhbZvruw0YRxyOhI'],
    ['10629', '2rjkp6UhbZvruw0YRxyOhI'],
    ['10630', '2rjkp6UhbZvruw0YRxyOhI'],
    ['10631', '2rjkp6UhbZvruw0YRxyOhI'],
    ['10626', '5NkDbVrwdCBu7cml70kB8T'],
    ['10622', '5hA4ubFB3kLcC0DDtjQT33'],
    ['10623', '5hA4ubFB3kLcC0DDtjQT33'],
    ['10624', '5hA4ubFB3kLcC0DDtjQT33'],
    ['10625', '5hA4ubFB3kLcC0DDtjQT33'],
    ['10621', '7DLo39iieZCwnGqHv9bzzk'],
    ['10620', '426a1Kp06Lb9cyjmCcTeaG'],
    ['10619', '3xELuHBlowY8Iydr7D18a5'],
    ['10616', '2ZkNFbPl2XyFtUiBgTLbWf'],
    ['10617', '2ZkNFbPl2XyFtUiBgTLbWf'],
    ['10618', '2ZkNFbPl2XyFtUiBgTLbWf'],
    ['10615', '14RBUh0E3cY1KtBYL5ZI1d'],
    ['10614', '5JtoJuNPBWJYk4IIbyvPhU'],
    ['10613', '2xy7HbPNzctgHouv9Lpin5'],
    ['10612', '31srAROZjQq7um2bKInSIW'],
    ['10611', '5Nq1nTLm0h9sOFzzw6oR4p'],
    ['10610', '2zVugo0817YbGXQnAI7ya6'],
    ['10609', '4qFrNlpIzgqGuNIgmzxkFN'],
    ['10416', '1oF0kBGMYk2o2BFyAOfvIK'],
    ['10415', '1oF0kBGMYk2o2BFyAOfvIK'],
    ['10602', '2TQlMeghr50M7bwfgATCvK'],
    ['10603', '2TQlMeghr50M7bwfgATCvK'],
    ['10604', '2TQlMeghr50M7bwfgATCvK'],
    ['10605', '2TQlMeghr50M7bwfgATCvK'],
    ['10606', '2TQlMeghr50M7bwfgATCvK'],
    ['10601', '60Nz3nBHTXFeufdhpmP8bh'],
    ['10544', '6gqoh29qqAIkUehU5JuHsH'],
    ['10540', '3c3QdGIuc1RamLE5YMVKQX'],
    ['10541', '3c3QdGIuc1RamLE5YMVKQX'],
    ['10542', '3c3QdGIuc1RamLE5YMVKQX'],
    ['10543', '3c3QdGIuc1RamLE5YMVKQX'],
    ['10539', '6tEV9mT4lnx0JEOz91wvk7'],
    ['10538', '2pBPV5dT3kutkpFIVtLQCc'],
    ['10537', 'FORAHmRvbNPixwRFYQQMw'],
    ['10536', '5uMAL2kEv4Os06DbPdTY35'],
    ['10535', '5ycPIHoW2ajqOxW9b7XhXA'],
    ['10534', '7vsVrB4JANsOWCdjd1JvuB'],
    ['10528', '2Xfe3WCKCpk5N5ywPf0ulc'],
    ['10529', '2Xfe3WCKCpk5N5ywPf0ulc'],
    ['10530', '2Xfe3WCKCpk5N5ywPf0ulc'],
    ['10531', '2Xfe3WCKCpk5N5ywPf0ulc'],
    ['10532', '2Xfe3WCKCpk5N5ywPf0ulc'],
    ['10533', '2Xfe3WCKCpk5N5ywPf0ulc'],
    ['10527', '1jV66bI8w9ZMHdIilDT4rk'],
    ['10526', '1TkImWYQVm413jtQBOWG5V'],
    ['10522', '4i3sLzhx6xFEAVKfxI6OG9'],
    ['10523', '4i3sLzhx6xFEAVKfxI6OG9'],
    ['10524', '4i3sLzhx6xFEAVKfxI6OG9'],
    ['10525', '4i3sLzhx6xFEAVKfxI6OG9'],
    ['10521', 'Zq211aeb6bBvQ7ktU9eia'],
    ['10517', '1yMIboZiZGALgDwTaitBOd'],
    ['10518', '1yMIboZiZGALgDwTaitBOd'],
    ['10519', '1yMIboZiZGALgDwTaitBOd'],
    ['10520', '1yMIboZiZGALgDwTaitBOd'],
    ['10516', '2zlYJWskkgpdVaJ5aHphRL'],
    ['10513', 'CEdORHMeHdVP6Q6YSwfuX'],
    ['10514', 'CEdORHMeHdVP6Q6YSwfuX'],
    ['10515', 'CEdORHMeHdVP6Q6YSwfuX'],
    ['10512', '44WbSXfjVAAe7fVlGEpUPD'],
    ['10505', 'xsnoDzAZ5IeRrQb4Xx930'],
    ['10506', 'xsnoDzAZ5IeRrQb4Xx930'],
    ['10507', 'xsnoDzAZ5IeRrQb4Xx930'],
    ['10508', 'xsnoDzAZ5IeRrQb4Xx930'],
    ['10509', 'xsnoDzAZ5IeRrQb4Xx930'],
    ['10510', 'xsnoDzAZ5IeRrQb4Xx930'],
    ['10511', 'xsnoDzAZ5IeRrQb4Xx930'],
    ['10504', '3pq5VfL0NU9JEqp3OIdZgj'],
    ['10503', '3flmUzq7FnVNr0n3llvDE7'],
    ['10502', '4hzm572RpUDUIWh3z5hfF2'],
    ['10501', '3zF1KUNdkB1P6m2DZHPTFU'],
    ['10426', '2wnr2mHdMLSclg6Lflv6tx'],
    ['10427', '2wnr2mHdMLSclg6Lflv6tx'],
    ['10428', '2wnr2mHdMLSclg6Lflv6tx'],
    ['10429', '2wnr2mHdMLSclg6Lflv6tx'],
    ['10430', '2wnr2mHdMLSclg6Lflv6tx'],
    ['10431', '2wnr2mHdMLSclg6Lflv6tx'],
    ['10432', '2wnr2mHdMLSclg6Lflv6tx'],
    ['10433', '2wnr2mHdMLSclg6Lflv6tx'],
    ['10434', '2wnr2mHdMLSclg6Lflv6tx'],
    ['10435', '2wnr2mHdMLSclg6Lflv6tx'],
    ['10425', '1C3kBRsHLx7d6WtgVyU1vu'],
    ['10424', '4mlqUi959EVr9hQwhlPAgM'],
    ['10423', '3nb8ZxJQirHrLY8JmAFTN2'],
    ['10419', '41hjaelsWEeRmagkC4c7iM'],
    ['10420', '41hjaelsWEeRmagkC4c7iM'],
    ['10421', '41hjaelsWEeRmagkC4c7iM'],
    ['10422', '41hjaelsWEeRmagkC4c7iM'],
    ['10418', '60cjhQ1YGeVGp11ZQQsmux'],
    ['10409', '5qZaQoBm15jPAbRZTbEHVM'],
    ['10414', '1frRpZv3SDW0iYCFmFSDts'],
    ['10413', '2QQWFLawZOveP4g78YjnZD'],
    ['10412', '6t2MsHwFLlw0yYNNUMRV2J'],
    ['10411', '6XuYuqKg8NC5zFg5dLe29W'],
    ['10403', '1IlhXZDz3TS2vSfaZEL6g5'],
    ['10317', '6PRaiVJxvUkxXdy6NB1LmH'],
    ['10316', '6PRaiVJxvUkxXdy6NB1LmH'],
    ['10315', '6PRaiVJxvUkxXdy6NB1LmH'],
    ['10314', '6PRaiVJxvUkxXdy6NB1LmH'],
    ['10405', 'oA5oolCY1HfgIRHe4DgXV'],
    ['10406', 'oA5oolCY1HfgIRHe4DgXV'],
    ['10407', 'oA5oolCY1HfgIRHe4DgXV'],
    ['10408', 'oA5oolCY1HfgIRHe4DgXV'],
    ['10404', '5iCE2IsmPFKfilqoTBLS5V'],
    ['10410', '5NRNSiaboHqXmpODUpTGF1'],
    ['10401', '1cxiPWvfULYMqjTfc7LM0L'],
    ['10331', '70w7owlC760Y6Tt06kAR9L'],
    ['10332', '70w7owlC760Y6Tt06kAR9L'],
    ['10333', '70w7owlC760Y6Tt06kAR9L'],
    ['10334', '70w7owlC760Y6Tt06kAR9L'],
    ['10335', '70w7owlC760Y6Tt06kAR9L'],
    ['10336', '70w7owlC760Y6Tt06kAR9L'],
    ['10337', '70w7owlC760Y6Tt06kAR9L'],
    ['10338', '70w7owlC760Y6Tt06kAR9L'],
    ['10339', '70w7owlC760Y6Tt06kAR9L'],
    ['10340', '70w7owlC760Y6Tt06kAR9L'],
    ['10330', '3calx9PrAsCc2E6SYqhIYQ'],
    ['10329', 'TYpzGIfqChPsvUU6rBgFj'],
    ['10328', '5Zs8Y6P1tM5arsSAksIYSH'],
    ['10327', '7GKbvpdAa26tbLxu4stat5'],
    ['10326', '6Kw97g5ZkIgvE4fytFrQgn'],
    ['10320', '4yWhobhq0ZqDsXQGRAMWXe'],
    ['10321', '4yWhobhq0ZqDsXQGRAMWXe'],
    ['10322', '4yWhobhq0ZqDsXQGRAMWXe'],
    ['10323', '4yWhobhq0ZqDsXQGRAMWXe'],
    ['10324', '4yWhobhq0ZqDsXQGRAMWXe'],
    ['10325', '4yWhobhq0ZqDsXQGRAMWXe'],
    ['10305', '7IuGwY52nsM48VlI1ghShj'],
    ['10318', '5btvxagEU9QQfnNbf8aOWC'],
    ['10313', '7D86ZjdT98RjLkihyoazQP'],
    ['10309', '6B4kbpQEj1hYwU6aOIuPn9'],
    ['10310', '6B4kbpQEj1hYwU6aOIuPn9'],
    ['10311', '6B4kbpQEj1hYwU6aOIuPn9'],
    ['10308', '2nLyMvjWfJafFSFhu12mtG'],
    ['10307', '6T10PLdnsP5meAz2yYNXfh'],
    ['10306', '3kkEdnK5V6dqbdnbWQNW5V'],
    ['10319', '5xUvdoeUECOcgDTpm7cEeb'],
    ['10131', '3Q7mJpmHC10KR7Elx2dJYc'],
    ['10130', '2tqDs9JGt2wjwuNGiIpwVG'],
    ['10129', '2A3RgGOdduTIL96GWtyRWn'],
    ['10128', '1H8KvbDx40OsacC9m5GExW'],
    ['10118', '1Kem6RBwl8NVleRha0a6YU'],
    ['10119', '1Kem6RBwl8NVleRha0a6YU'],
    ['10120', '1Kem6RBwl8NVleRha0a6YU'],
    ['10121', '1Kem6RBwl8NVleRha0a6YU'],
    ['10122', '1Kem6RBwl8NVleRha0a6YU'],
    ['10123', '1Kem6RBwl8NVleRha0a6YU'],
    ['10124', '1Kem6RBwl8NVleRha0a6YU'],
    ['10125', '1Kem6RBwl8NVleRha0a6YU'],
    ['10126', '1Kem6RBwl8NVleRha0a6YU'],
    ['10127', '1Kem6RBwl8NVleRha0a6YU'],
    ['10117', '4aVsXrYUvQoiEHOfWhq9P5'],
    ['10116', '4pK00kb9sxM6Ic4vkIBg2U'],
    ['10115', '4ZQIbSbnkIS2TKuyyceOxY'],
    ['10114', '3rZ8xYLtlFRFBWykYXyUmo'],
    ['10113', '31nu3bSS29Wqg4DCxc4QBG'],
    ['10108', '3yku8ix8LvU1HumOWPR7Of'],
    ['10111', '1VEYbOh4llXX8ygZF7M09X'],
    ['10109', '6HT0vQWffwlS5kn8HlTfhv'],
    ['10110', '6HT0vQWffwlS5kn8HlTfhv'],
    ['10112', '6Up6ei6C103vppkwFvk1ME'],
    ['10107', '74m8zpgtvTcRs53O5ASdug'],
    ['10105', '5VO6Ozv9u7pHnerdzyiDsR'],
    ['10106', '5VO6Ozv9u7pHnerdzyiDsR'],
    ['10104', '1BgILza8uugS4YG3riQ0Fj'],
    ['10102', 'SErcAPx3ms9Yk1cEHiEiQ'],
    ['10103', 'SErcAPx3ms9Yk1cEHiEiQ'],
    ['10101', '3H1H8HWuEV1ktZbdmdSKN7'],
    ['10005', '75BEWiYnia2FvTulRAQSir'],
    ['10004', '5QB0YeqXPWeAMEI5I7SHM5'],
    ['10003', '2ULXrVlgb8cX4zqjDcodPC'],
    ['10002', '1iJtnFXQhJKMDtUa3MHv44'],
    ['30019', '5EDZXpCpMGqSsnwW6HBy9k'],
    ['30018', '4rhrZbmpGmyryu5APaoWUV'],
    ['30017', '5lfG9vjNFlR8YNDEs38ZQU'],
    ['30016', '4eQAstGVexmkVtS2UYFvxM'],
    ['30015', '7lwJPlzsq4p3xqzboRV8bX'],
    ['30014', '75cpQH2YxUhLK81PLOSMgl'],
    ['30004', '6hqHLYCqSXwzl24Oe4M9ua'],
    ['30005', '6hqHLYCqSXwzl24Oe4M9ua'],
    ['30006', '6hqHLYCqSXwzl24Oe4M9ua'],
    ['30007', '6hqHLYCqSXwzl24Oe4M9ua'],
    ['30008', '6hqHLYCqSXwzl24Oe4M9ua'],
    ['30009', '6hqHLYCqSXwzl24Oe4M9ua'],
    ['30010', '6hqHLYCqSXwzl24Oe4M9ua'],
    ['30011', '6hqHLYCqSXwzl24Oe4M9ua'],
    ['30012', '6hqHLYCqSXwzl24Oe4M9ua'],
    ['30013', '6hqHLYCqSXwzl24Oe4M9ua'],
    ['30003', '30GuoVUc5ZiclKlNjaJkSe'],
    ['30002', '2Wtdtzgw5bVDa5HBKDZjBi'],
    ['30001', '7pA2JNLBiUFbvF6MmqdCg3'],
    ['10234', '10Clay1vHWnH3EMjgH737h'],
    ['10235', '10Clay1vHWnH3EMjgH737h'],
    ['10236', '10Clay1vHWnH3EMjgH737h'],
    ['10223', '3NQOxSFMMZbmgpxoOGgc2X'],
    ['10224', '3NQOxSFMMZbmgpxoOGgc2X'],
    ['10233', '5N2UNocRMyq1dSluMRB140'],
    ['10237', '3B8GoZz6koJ3HlxoyjiQty'],
    ['10238', '3B8GoZz6koJ3HlxoyjiQty'],
    ['10239', '3B8GoZz6koJ3HlxoyjiQty'],
    ['10240', '3B8GoZz6koJ3HlxoyjiQty'],
    ['10241', '3B8GoZz6koJ3HlxoyjiQty'],
    ['10242', '3B8GoZz6koJ3HlxoyjiQty'],
    ['10243', '3B8GoZz6koJ3HlxoyjiQty'],
    ['10244', '3B8GoZz6koJ3HlxoyjiQty'],
    ['10245', '3B8GoZz6koJ3HlxoyjiQty'],
    ['10246', '3B8GoZz6koJ3HlxoyjiQty'],
    ['10201', '7iGXFvJx1ZftzuV4BqApwP'],
    ['10226', '3ijMz12oPsKMmPX7bCWpnK'],
    ['10227', '3ijMz12oPsKMmPX7bCWpnK'],
    ['10228', '3ijMz12oPsKMmPX7bCWpnK'],
    ['10229', '3ijMz12oPsKMmPX7bCWpnK'],
    ['10230', '3ijMz12oPsKMmPX7bCWpnK'],
    ['10222', '1x1LXk3eUE8aeFJkOt9JWN'],
    ['10225', 'YRUwwbya1BObdRtcEEjtq'],
    ['30111', '6ITtwIIOLWbqKyvsauQAk1'],
    ['10214', '67SnRiMncpmubh2qiTgYBb'],
    ['10215', '67SnRiMncpmubh2qiTgYBb'],
    ['10216', '67SnRiMncpmubh2qiTgYBb'],
    ['10217', '67SnRiMncpmubh2qiTgYBb'],
    ['10218', '67SnRiMncpmubh2qiTgYBb'],
    ['10219', '4uFOsdrhq6GPHGWUa9CA39'],
    ['10220', '4uFOsdrhq6GPHGWUa9CA39'],
    ['10221', '4uFOsdrhq6GPHGWUa9CA39'],
    ['10231', '2UlUOQHKjQCfIcNO1D2Ydi'],
    ['10232', 'W9g2hqk9q5ikr1txIt5by'],
    ['10209', 'nDuHe1TbaxTq6ktELuDjJ'],
    ['10210', 'nDuHe1TbaxTq6ktELuDjJ'],
    ['10211', 'nDuHe1TbaxTq6ktELuDjJ'],
    ['10212', 'nDuHe1TbaxTq6ktELuDjJ'],
    ['10213', 'nDuHe1TbaxTq6ktELuDjJ'],
    ['10202', 'JsaPHezd0v0VHMf3IlOKl'],
    ['10203', 'JsaPHezd0v0VHMf3IlOKl'],
    ['10204', 'JsaPHezd0v0VHMf3IlOKl'],
    ['10205', 'JsaPHezd0v0VHMf3IlOKl'],
    ['10247', 'uo1ofjowSY7ldJnchJUuY'],
    ['10206', 'jRKmVZs9XmgV0u6JTDjWv'],
    ['10207', 'jRKmVZs9XmgV0u6JTDjWv'],
    ['10208', 'jRKmVZs9XmgV0u6JTDjWv'],
    ['12006', '7emtEzF2fAywXGPSmYHM9P'],
    ['10116', '5dB5aJkAhRKQ0RFxyBa4Bn'],
    ['30032', '5325sM9YbfSrLEj0d1jcI8'],
    ['300224', '2seFdy8syTusz28yl5S4a4'],
    ['300223', '3DNw7ALOvM6dOmZkoeRhh7'],
    ['300221', '6Up4jBroWLbQIhtBeKc6qq'],
    ['101994', '5AO7GuYNoJznjnq1AcGP7Y'],
    ['101993', '77mLxjg3AkQHEFOVGeiGmk'],
    ['104015', '6Gvq7WoPsI6VxtfggruQJ8'],
    ['101992', '6XCYXDnPSbSG7OiQQoxId6'],
    ['101902', '3wI24GyBFs7YOdxJJoHOj'],
    ['102326', '4n026z8aapNyO2eoTg8nI9'],
    ['101371', '1uFA3D0dHDAmj4R55X0XZG'],
    ['101991', '7g1BBm4HKbii0TRKpe6fIC'],
    ['101901', '24VCowFOei9omNcc7XBGZG'],
    ['101370', '3H5kG6qnnRJ5s3pNUNXkRo'],
    ['102325', '5kB4DuzsWCwY7AOHoppbd0'],
    ['101996', '5JPUzWSxKqwlAx8FytBIUD'],
    ['101990', '1SH9kcvqItFFJwBsxpzSDI'],
    ['100232', '3xXUVH1ZyUzzV5kZcJdClb'],
    ['140010', '5SL4f0vsaVeDPAWFDPBEbP'],
    ['40010', '4VRRJBYNBoYhm2FywBbpp1'],
]);
