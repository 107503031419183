import { BaseHouseholdDto } from '../../../dtos/internal-api/user/household/household.dto';
import { InternalDogDtoMock } from '../dog/internal-dog.dto.mock';
import { InternalPublicUserDtoMock } from '../user/internal-public-user.dto.mock';

export class BaseHouseholdDtoMock {
    private defaultValue: BaseHouseholdDto = {
        id: 'uuid-1234',
        name: 'household-123',
        users: [new InternalPublicUserDtoMock().value],
        dogs: [new InternalDogDtoMock().value],
    };

    constructor(overrides?: Partial<BaseHouseholdDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): BaseHouseholdDto {
        return this.defaultValue;
    }
}
