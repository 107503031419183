<div class="star-rating">
    @for (star of stars(); track $index) {
        <div
            [class]="'star-rating__star'"
            [class.star-rating__star--selected]="$index + 1 <= starValueSelected()"
            [attr.data-test]="'star-rating-' + ($index + 1)"
            (click)="ratingChecked($index + 1)"
        >
            <i class="fa-solid fa-star fa-xl"></i>
        </div>
    }
</div>
