import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ContentService } from '../../content.service';
import { getSeasonalTopics, getSeasonalTopicsFailure, getSeasonalTopicsSuccess } from './seasonal-topic.actions';

@Injectable()
export class SeasonalTopicEffects {
    private readonly actions$ = inject(Actions);
    private readonly contentService = inject(ContentService);

    getSeasonalTopics$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getSeasonalTopics),
            switchMap(({ params }) => {
                return this.contentService.getSeasonalTopics(params).pipe(
                    map((seasonalTopics) => {
                        return getSeasonalTopicsSuccess({ seasonalTopics });
                    }),
                    catchError((error: Error) => {
                        return of(getSeasonalTopicsFailure({ error }));
                    }),
                );
            }),
        );
    });
}
