import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import {
    foundValidUserSession,
    getUserSignInProviderSuccess,
    loginWithApple,
    loginWithAppleFailure,
    loginWithAppleSuccess,
    loginWithEmailAndPassword,
    loginWithEmailAndPasswordFailure,
    loginWithEmailAndPasswordSuccess,
    loginWithGoogle,
    loginWithGoogleFailure,
    loginWithGoogleSuccess,
    noValidUserSession,
    requestPasswordReset,
    requestPasswordResetFailure,
    requestPasswordResetSuccess,
    signUpWithEmailAndPassword,
    signUpWithEmailAndPasswordFailure,
    signUpWithEmailAndPasswordSuccess,
} from './authentication.actions';
import { LoadingState } from '@frontend/data-access/shared-models';

export const authenticationFeatureKey = 'authentication';

export interface AuthError extends Error {
    code: string;
}

export interface AuthState {
    signUpLoadingState: LoadingState;
    signUpError: AuthError | undefined;
    loginLoadingState: LoadingState;
    loginError: AuthError | undefined;
    loggedIn: boolean;
    resetPasswordLoading: boolean;
    resetPasswordError: AuthError | undefined;
    resetPasswordSuccess: boolean;
    signInProvider: string | undefined;
    isNewUser: boolean | undefined;
}

export const initialState: AuthState = {
    signUpLoadingState: LoadingState.INIT,
    signUpError: undefined,
    loginLoadingState: LoadingState.INIT,
    loginError: undefined,
    loggedIn: false,
    resetPasswordLoading: false,
    resetPasswordError: undefined,
    resetPasswordSuccess: false,
    signInProvider: undefined,
    isNewUser: false,
};

export const authenticationFeature = createFeature({
    name: authenticationFeatureKey,
    reducer: createReducer(
        initialState,
        on(
            loginWithApple,
            loginWithGoogle,
            (state): AuthState => ({
                ...state,
                loginLoadingState: LoadingState.LOADING,
                loginError: undefined,
                signUpLoadingState: LoadingState.LOADING,
                signUpError: undefined,
            }),
        ),
        on(
            loginWithAppleSuccess,
            loginWithGoogleSuccess,
            (state, { user, additionalUserInfo }): AuthState => ({
                ...state,
                loginLoadingState: LoadingState.LOADED,
                loginError: undefined,
                signUpLoadingState: LoadingState.LOADED,
                signUpError: undefined,
                signInProvider: user.signInProvider,
                isNewUser: additionalUserInfo?.isNewUser,
            }),
        ),
        on(
            loginWithAppleFailure,
            loginWithGoogleFailure,
            (state, action): AuthState => ({
                ...state,
                loginLoadingState: LoadingState.LOADED,
                loginError: action.error,
                signUpLoadingState: LoadingState.LOADED,
                signUpError: action.error,
            }),
        ),
        on(
            loginWithEmailAndPassword,
            (state): AuthState => ({ ...state, loginLoadingState: LoadingState.LOADING, loginError: undefined }),
        ),
        on(
            loginWithEmailAndPasswordSuccess,
            (state, { user }): AuthState => ({
                ...state,
                loginLoadingState: LoadingState.LOADED,
                loginError: undefined,
                signInProvider: user.signInProvider,
            }),
        ),
        on(
            loginWithEmailAndPasswordFailure,
            (state, { error }): AuthState => ({
                ...state,
                loginLoadingState: LoadingState.LOADED,
                loginError: error,
            }),
        ),
        on(
            signUpWithEmailAndPassword,
            (state): AuthState => ({
                ...state,
                signUpLoadingState: LoadingState.LOADING,
                signUpError: undefined,
                isNewUser: true,
            }),
        ),
        on(
            signUpWithEmailAndPasswordSuccess,
            (state, { user }): AuthState => ({
                ...state,
                signUpLoadingState: LoadingState.LOADED,
                signUpError: undefined,
                signInProvider: user.signInProvider,
            }),
        ),
        on(
            signUpWithEmailAndPasswordFailure,
            (state, { error }): AuthState => ({
                ...state,
                signUpLoadingState: LoadingState.LOADED,
                signUpError: error,
            }),
        ),
        on(foundValidUserSession, (state): AuthState => ({ ...state, loggedIn: true })),
        on(noValidUserSession, (state): AuthState => ({ ...state, loggedIn: false })),
        on(
            requestPasswordReset,
            (state): AuthState => ({ ...state, resetPasswordLoading: true, resetPasswordError: undefined }),
        ),
        on(
            requestPasswordResetSuccess,
            (state): AuthState => ({
                ...state,
                resetPasswordLoading: false,
                resetPasswordError: undefined,
                resetPasswordSuccess: true,
            }),
        ),
        on(
            requestPasswordResetFailure,
            (state, action): AuthState => ({
                ...state,
                resetPasswordLoading: false,
                resetPasswordError: action.error,
                resetPasswordSuccess: false,
            }),
        ),
        on(getUserSignInProviderSuccess, (state, { signInProvider }) => ({
            ...state,
            signInProvider,
        })),
    ),
    extraSelectors: ({ selectAuthenticationState }) => ({
        selectSignUpLoading: createSelector(
            selectAuthenticationState,
            (state) => state.signUpLoadingState === LoadingState.LOADING,
        ),
        selectLoginLoading: createSelector(
            selectAuthenticationState,
            (state) => state.loginLoadingState === LoadingState.LOADING,
        ),
        selectIsNewUser: createSelector(selectAuthenticationState, (state) => state.isNewUser),
    }),
});
