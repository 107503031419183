import { AgeAlternativeValues, Gender } from '@shared/user-domain';
import { DomainPartnerDto } from '../partner/domain-partner.dto';

export class DomainBaseUserDto {
    name!: string | null;
    ownerName!: string | null;
    dateOfBirth!: string | AgeAlternativeValues | null;
    gender!: Gender | null;
    dateOfArrival!: string | null;
    hasArrived!: boolean | null;
    countryCode!: string | null;
    breedId!: string | null;
}

export class DomainUserWithoutTokenDto extends DomainBaseUserDto {
    id = '';
    accountId!: string | null;
    mail!: string;
    termsAndPrivacyAccepted!: boolean;
    notifications!: boolean;
    dateOfOnboarding!: string | null;
    partners!: DomainPartnerDto[];
    firstDayAtHomeWhenOnboarding?: boolean; //TODO - remove this prop
    purinaMarketingAccepted!: boolean | null;
    isRescuePup!: boolean | null;
    isApproximateDateOfBirth!: boolean | null;
}

export class DomainUserDto extends DomainUserWithoutTokenDto {
    userToken!: string;
}
