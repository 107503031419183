import { mapDateOfBirthToAgeInWeeks } from '@shared/utils/typescript';
import { selectBreeds } from '@frontend/data-access/contentful';
import { createSelector } from '@ngrx/store';
import { getBreedInfo } from '../data-access/client-info/client-info.utils';
import { supportPortalClientInfoFeature } from '../data-access/client-info/client-info.reducer';

export const selectUserInformationSidebarVm = createSelector(
    supportPortalClientInfoFeature.selectClientInfoState,
    selectBreeds,
    (clientInfo, breeds) => {
        const today = new Date();

        return {
            dogName: clientInfo.name,
            ownerName: clientInfo.ownerName,
            breed: getBreedInfo(clientInfo, breeds)?.name,
            breedType: getBreedInfo(clientInfo, breeds)?.breedGroup.name,
            gender: clientInfo.gender,
            age: mapDateOfBirthToAgeInWeeks(clientInfo.dateOfBirth, today),
            hasArrived: clientInfo.hasArrived,
            weeksTillArrival: clientInfo.dateOfArrival
                ? mapDateOfBirthToAgeInWeeks(clientInfo.dateOfArrival, today)
                : undefined,
            countryCode: clientInfo.countryCode ?? '',
        };
    },
);
