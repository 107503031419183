import { Gender } from '@shared/user-domain';

export class DomainDogDto {
    id!: string;
    name: string | null = null;
    dateOfArrival: number | null = null;
    dateOfBirth: number | null = null;
    dateOfOnboarding: number | null = null;
    hasArrived: boolean | null = null;
    gender: Gender | null = null;
    breedId: string | null = null;
    isRescue: boolean | null = null;
    isApproximateDateOfBirth: boolean | null = null;
}
