import { InternalAgentDto } from '@shared/user-api-interface';
import { Profile } from './profile.model';

export function mapAgentDtoToProfile(user: InternalAgentDto): Profile {
    return {
        id: user.id,
        name: user.name ?? '',
        email: user.mail ?? '',
        token: user.userToken ?? '',
    };
}
