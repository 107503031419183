import { IsDate, IsNumber, IsOptional, IsString } from 'class-validator';

export class DomainPostTodayProgressCommand {
    @IsOptional()
    @IsString()
    dogId?: string;

    @IsString()
    courseId!: string;

    @IsNumber()
    numberOfStepsCompleted!: number;

    @IsDate()
    date!: Date;
}
