import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { addApiKeyAndJwtToRequest, AuthenticationService } from '@frontend/data-access/user/authentication';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectToken } from '../data-access/profile/profile.selectors';
import { internalPaths } from '@shared/user-api-interface';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    private readonly authenticationService = inject(AuthenticationService);
    private readonly store = inject(Store);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);
    private userToken$ = this.store.select(selectToken);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            request.url === `${this.environment.internalApiUrl}/api/${internalPaths.agentsPath}` ||
            request.url.startsWith(`${this.environment.internalApiUrl}/api/${internalPaths.chatTagsPath}`)
        ) {
            return addApiKeyAndJwtToRequest(
                this.authenticationService.token$,
                this.environment.applicationToInternalHashedApiKey,
                request,
                next,
            );
        }

        if (request.url.startsWith(this.environment.internalApiUrl)) {
            return addApiKeyAndJwtToRequest(
                this.userToken$,
                this.environment.applicationToInternalHashedApiKey,
                request,
                next,
            );
        }
        return next.handle(request);
    }
}
