export enum LessonProgressType {
    READ = 'read',
    SKIPPED = 'skipped',
    COMPLETED = 'completed',
}

export enum ArticleProgressType {
    COMPLETED = 'completed',
}

export type StepProgressType = LessonProgressType | ArticleProgressType;

export enum CourseProgressType {
    COMPLETED = 'completed',
    IN_PROGRESS = 'in progress',
    NOT_COMPLETED = 'not completed',
}

export enum RescheduledStepType {
    PRACTICED = 'practiced',
    POSTPONED = 'postponed',
}

export enum StateType {
    STEP = 'step',
    ARTICLE = 'article',
    DAILY_BITE = 'daily bite',
}
