import { InternalHouseholdUserDto, InternalPublicUserDto } from '../internal-user.dto';
import { InternalDogDto } from '../dog/dog.dto';

// Used by support portal
export class BaseHouseholdDto {
    id!: string;
    name!: string;
    users!: InternalPublicUserDto[];
    dogs!: InternalDogDto[];
}

export class InternalHouseholdDto extends BaseHouseholdDto {
    currentUser!: InternalHouseholdUserDto;
}
