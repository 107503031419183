import { GetContentBaseParams, InternalSeasonalTopicDto } from '@shared/content-api-interface';
import { createAction, props } from '@ngrx/store';

export const getSeasonalTopics = createAction(
    '[Content] Get Seasonal Topics',
    props<{ params: GetContentBaseParams }>(),
);

export const getSeasonalTopicsSuccess = createAction(
    '[Content] Get Seasonal Topics Success',
    props<{ seasonalTopics: InternalSeasonalTopicDto[] }>(),
);

export const getSeasonalTopicsFailure = createAction(
    '[Content] Get Seasonal Topics Failure',
    props<{
        error: Error;
    }>(),
);
