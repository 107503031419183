import { createFeature, createReducer, on } from '@ngrx/store';
import { SeasonalTopic, SeasonalTopicsState } from '../../models/seasonal-topic.model';
import { getSeasonalTopicsSuccess } from './seasonal-topic.actions';

const initialState: SeasonalTopicsState = {
    seasonalTopics: [],
};

export const seasonalTopicsFeature = createFeature({
    name: 'seasonalTopics',
    reducer: createReducer(
        initialState,
        on(
            getSeasonalTopicsSuccess,
            (state, { seasonalTopics }): SeasonalTopicsState => ({
                ...state,
                seasonalTopics: seasonalTopics as SeasonalTopic[],
            }),
        ),
    ),
});
