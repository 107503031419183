import { BreedInfo } from '@frontend/data-access/contentful';
import { ClientInfo } from './client-info.model';
import { BaseHouseholdDto } from '@shared/user-api-interface';
import { differenceInDays } from 'date-fns';

export const mapBaseHouseholdDtoToClientInfo = (household: BaseHouseholdDto): ClientInfo => {
    const dog = household.dogs[0];
    const user = household.users[0];

    return {
        firstDayAtHomeWhenOnboarding:
            differenceInDays(new Date(dog.dateOfOnboarding!), new Date(dog.dateOfArrival!)) === 0,
        name: dog.name,
        ownerName: user.name,
        dateOfBirth: dog.dateOfBirth ? new Date(dog.dateOfBirth).toISOString() : '',
        breedId: dog.breedId,
        gender: dog.gender,
        dateOfArrival: dog.dateOfArrival ? new Date(dog.dateOfArrival).toISOString() : '',
        hasArrived: dog.hasArrived,
        countryCode: user.countryCode,
    };
};

export const getBreedInfo = (clientInfo: ClientInfo, breeds: BreedInfo[]): BreedInfo | undefined => {
    return breeds.find((breed) => clientInfo.breedId === breed.id);
};
